import React from "react"

type careerCardProps = {
    title: string,
    description: string,
    className?: string,
    path?: any,
    onClick?: any
}
const CareerCard: React.FC<careerCardProps> = ({ title, description, path, className
}) => {
    return (
        <>
            <div
                className={`bg-white rounded text-center career-card text-dark h-100 ${className}`} >
                <h3 className='font-20 fw-700'>{title}</h3>
                <p className='mb-0'>{description}</p>
            </div>
        </>
    )
}

export default CareerCard
