import React from "react"
import { useNavigate } from "react-router-dom"
import { Button, Col, Row } from "reactstrap"

type applyCardProps = {
  post: string;
  address: string;
  jobType: string;
  id: any;
  className?: string;
  onClick?: any;
  slug?: string;
};
const ApplyCard: React.FC<applyCardProps> = ({
  post,
  address,
  jobType,
  id,
  slug,
  className,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="apply-row">
        <Row className="justify-content-between">
          <Col>
            <div>
              <h4 className="font-20">{post}</h4>
              <p className="text-muted mb-0">
                {address}, {jobType}
              </p>
            </div>
          </Col>
          <Col xs="auto">
            <Button
              color="primary"
              outline
              className="btn-rounded"
              onClick={() => {
                navigate(`${slug}`);
              }}
            >
              Apply Now
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ApplyCard;
