import { toAbsoluteUrl } from "../../helper/AssetHelpers";

const IsLoading = () => {
  return (
    <div className="text-center mt-3">
      {/* <img
                src={toAbsoluteUrl("/media/icons/loader.svg")}
                alt="Loading..." /> */}
      <div className="spinner-border text-secondary" role="status">
        {/* <span className="sr-only">Loading...</span> */}
      </div>
    </div>
  );
};

export default IsLoading;
