import React from "react"
import "../src/theme/tenant1/css/style.min.css"
import Home from "./app/pages/home"

function App() {
    return (
        <div className="App">
            <Home/>
        </div>
    );
}

export default App;
