import React from "react"
import { useNavigate } from "react-router-dom"
import { KTSVG } from "../../../helper/components/KTSVG"

type Props = {
    sectionTitle?: string,
    sectionPara?: string,
    isButton?: boolean,
    btnText?: string,
    url?: any
}

const SectionHeadingStart: React.FC<Props> = ({ sectionTitle, sectionPara, isButton, btnText, url }) => {
    const navigate: any = useNavigate()
    return (
        <>
            <div className="title text-start">
                <h2>{sectionTitle}</h2>
                <KTSVG
                    path='/media/images/title_leaf.svg'
                    className='text-success w-auto mb-10px'
                /> <p>{sectionPara}</p>
                {isButton
                    ? <a href="JAVASCRIPT:void(0)" className='btn btn-outline-primary btn-rounded' onClick={() => navigate(url)}>{btnText}</a>
                    : <></>
                }

            </div>
        </>
    )
}

export default SectionHeadingStart
