import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import { Col, Container, Row } from "reactstrap";
import { goToTop } from "../../../_eaFruitsCms/helpers/CommonHelpers";
import { getRequest } from "../../../_eaFruitsCms/https/apiCall";
import {
  PRODUCT_GROUP_LIST,
  SINGLE_BRANDS,
} from "../../../_eaFruitsCms/https/ApiRoutes";
import ProductCard from "../../../layout/components/cards/productCard";
import SectionHeading from "../../../layout/components/headings/sectionHeading";
import MasterLayout from "../../../layout/masterlayout";
import CustomHelmet from "../../components/CustomHelmet";
import CustomLoader from "../../components/CustomLoader";

const BrandDetail = (props: any) => {
  const navigate = useNavigate();
  let { brandSlug } = useParams();
  const currentURL = window.location.href;

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  //TODO: why Kriti use brands and products as this is detail page
  const [brands, setBrands] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [displayLoader, setDisplayLoader] = useState<boolean>(false);

  useEffect(() => {
    goToTop(setDisplayLoader);
    fetchBrands().then();
    fetchProducts().then();
  }, []);

  const fetchBrands = async () => {
    setDisplayLoader(true);
    const response = await getRequest(
      SINGLE_BRANDS + `/${brandSlug}`,
      navigate,
      false
    );
    setBrands(response);
    setDisplayLoader(false);
  };

  const fetchProducts = async () => {
    setDisplayLoader(true);
    const response = await getRequest(
      PRODUCT_GROUP_LIST + `?brand_slug=${brandSlug}`,
      navigate,
      false
    );
    setProducts(response.results);
    setDisplayLoader(false);
  };

  return (
    <>
      <CustomHelmet
        title={`${brands && brands.name} | EA Foods`}
        metaDescription={`Know how ${
          brands && brands.name
        } is associated with East Africa Foods Co.`}
        imagePath={brands.brand_image}
      />
      {displayLoader && <CustomLoader />}
      {/* brand detail section */}
      <section className="section-padding">
        <Container>
          <Row className="justify-content-center">
            <Col lg="3" xl="3">
              <div>
                <img
                  src={brands.brand_image}
                  // srcSet={toAbsoluteUrl("/media/images/brandimg@2x.jpg 2x")}
                  className="img-fluid brand-img rounded shadow"
                  alt=""
                />
              </div>
            </Col>
            <Col lg="9" xl="9">
              <div className="blog-detail-desc mt-4 mt-md-0">
                <h1>{brands.name}</h1>
                <div
                  dangerouslySetInnerHTML={{ __html: brands.brand_story }}
                ></div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* products slider section start */}
      <section className="section-padding bg-light">
        <SectionHeading sectionTitle={`${brands.name} Products`} />

        <Container>
          <div>
            {products.length > 0 && (
              <Slider className="mt-50px" {...settings}>
                {products.map((products: any) => (
                  <>
                    <a
                      href="JAVASCRIPT:void(0)"
                      className="text-dark"
                      onClick={() => {
                        localStorage.setItem("productId", products.id);
                        navigate(
                          `/product/${
                            products.slug
                              ? products.slug
                              : products.name.replace(" ", "-").toLowerCase()
                          }`
                        );
                      }}
                    >
                      <ProductCard
                        title={products.name}
                        description={products.short_description}
                        path={products.group_image}
                      />
                    </a>
                  </>
                ))}
              </Slider>
            )}
          </div>
        </Container>
      </section>
      {/* products slider section end */}
    </>
  );
};

export default MasterLayout(BrandDetail);
