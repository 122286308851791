import React from "react"
import { useNavigate } from "react-router-dom"
import { Button, Col, Container, Row, } from "reactstrap"
import { toAbsoluteUrl } from "../../helper/AssetHelpers"
import MasterLayout from "../../layout/masterlayout"

const ErrorPage = (props: any) => {
    const navigate = useNavigate();
    return (
        <>
            <section className="section-padding error-page">
                <Container>
                    <Row className="justify-content-center">
                        <Col xs='10'>
                            <div className="text-center p">
                                <img
                                    src={toAbsoluteUrl(
                                        "/media/error.png"
                                    )}
                                    alt=""
                                    className="img-fluid"
                                />
                                <p className="font-28 fw-500">Oops! That Page Can't Be Found.</p>
                                <p className="mb-30px pb-10px">Sorry about that. Try searching for something else.</p>
                                <Button color="primary" className="btn-rounded"
                                    onClick={() => {
                                        navigate("/");
                                    }}
                                >Go Back to Home</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default MasterLayout(ErrorPage);
