import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import { goToTop } from "../../_eaFruitsCms/helpers/CommonHelpers";
import { getRequest } from "../../_eaFruitsCms/https/apiCall";
import {
  BRAND_LIST,
  TESTIMONIALS_LIST,
} from "../../_eaFruitsCms/https/ApiRoutes";
import { metaImage, toAbsoluteUrl } from "../../helper/AssetHelpers";
import TeastimonialCard from "../../layout/components/cards/testimonialCard";
import SectionHeading from "../../layout/components/headings/sectionHeading";
import SectionHeadingStart from "../../layout/components/headings/sectionHeadingStart";
import NoRecords from "../../layout/components/noRecords/noRecords";
import MasterLayout from "../../layout/masterlayout";
import CustomHelmet from "../components/CustomHelmet";
import CustomLoader from "../components/CustomLoader";
import NumberAnimated from "../components/NumberAnimated";
import IsLoading from "../components/Loader";

const Home = () => {
  const navigate = useNavigate();

  const [brands, setBrands] = useState<any>([]);
  const [testimonial, setTestimonial] = useState<any>([]);
  const [displayLoader, setDisplayLoader] = useState<boolean>(false);
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [isTestimonialLoading, setIsTestimonialLoading] =
    useState<boolean>(true);
  const [isBrandsLoading, setIsBrandsLoading] = useState<boolean>(true);

  useEffect(() => {
    goToTop(setDisplayLoader);
    fetchBrands().then();
    fetchTestimonial().then();
  }, []);

  const fetchBrands = async () => {
    setDisplayLoader(true);
    setIsBrandsLoading(true);
    const response = await getRequest(BRAND_LIST + `?limit=4`, navigate, false);
    setBrands(response.results);
    setDisplayLoader(false);
    setIsBrandsLoading(false);
  };
  const fetchTestimonial = async () => {
    setDisplayLoader(true);
    setIsTestimonialLoading(true);
    const response = await getRequest(
      TESTIMONIALS_LIST + `?limit=3`,
      navigate,
      false
    );

    setLoadMore(false);

    if (response.next && !["", null, "null"].includes(response.next)) {
      setLoadMore(true);
    }

    setTestimonial(response.results);
    setDisplayLoader(false);
    setIsTestimonialLoading(false);
  };

  return (
    <>
      <CustomHelmet
        title={`For Quality Fresh Produce East Africa Foods Co. We are the Best`}
        metaDescription={`Welcome to EA Foods website. East Africa Foods Co. is a social enterprise integrating sellers with buyers of fresh fruits, vegetables and grains.`}
        imagePath={metaImage}
      />

      {/* {displayLoader && <CustomLoader />} */}

      {/* <section className="hero-banner position-relative homehero-banner">
        <Container className="h-100" fluid="xl">
          <div className="position-relative h-100">
            <Row className="align-items-center h-100">
              <Col lg={6}>
                <div className="py-4">
                  <h1 className="herotitle"> For Quality Fresh Produce</h1>
                  <p className="mb-0 font-20">
                    East Africa Foods Co. is a social enterprise integrating
                    sellers with
                  </p>
                  <p className=" font-20">
                    buyers of fresh fruits, vegetables and grains.
                  </p>
                  <Button
                    color="primary"
                    outline
                    className="btn-rounded"
                    onClick={() => {
                      navigate("farmer-services");
                    }}
                  >
                    Explore Our Services
                  </Button>
                </div>
                <img
                  src={toAbsoluteUrl("/media/illustrations/heroBgVector.svg")}
                  alt=""
                  className="floating animated-shape"
                />
              </Col>
              <Col lg={6}>
                <div className="dir-hero-img">
                  <img
                    src={toAbsoluteUrl(
                      "/media/illustrations/homeHeroBanner.svg"
                    )}
                    alt=""
                    className="hero-vector"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <img
          src={toAbsoluteUrl("/media/illustrations/homeHeroBG.svg")}
          alt=""
          className="bg-hero-shape img-fluid"
        />
      </section> */}

      <section
        className="hero-banner position-relative homehero-banner"
        style={{
          backgroundImage: `url(${toAbsoluteUrl(
            "/media/images/herohome.jpg"
          )})`,
        }}
      >
        <div className="hero-overlay position-absolute"></div>
        <Container className="h-100" fluid="xl">
          <div className="position-relative h-100">
            <Row className="align-items-center h-100">
              <Col xs={12} md={7} lg={6}>
                <div className="py-4">
                  <h1 className="herotitle">For Quality Fresh Produce</h1>
                  <p className="font-20">
                    We believe that you shouldn’t have to choose between price
                    and quality when it comes to purchasing EA Foods’ products
                    or services. That’s why we offer a wide range of products
                    and services that deliver both unbeatable value and
                    exceptional quality.
                  </p>
                  <Button
                    color="primary"
                    outline
                    className="btn-rounded"
                    onClick={() => {
                      navigate("farmer-services");
                    }}
                  >
                    Explore Our Services
                  </Button>
                </div>
                <img
                  src={toAbsoluteUrl("/media/illustrations/heroBgVector.svg")}
                  alt=""
                  className="floating animated-shape"
                />
              </Col>
            </Row>
          </div>
        </Container>
        <img
          src={toAbsoluteUrl("/media/images/heroHomeM.jpg")}
          alt=""
          className="img-fluid hero-responsive d-block d-md-none"
        />
      </section>
      <section className="section-padding">
        <Container>
          <Row>
            <Col md={6}>
              <SectionHeadingStart
                sectionTitle="Build the bridge between local farmers & the market using Agri-tech"
                sectionPara="We aggregate demand and deliver wide range of fresh and exotic produce directly from farms to stores of B2B customers - retailers, wholesalers, local Customers, restaurants and cafés, hotels and exporters."
                isButton={true}
                btnText="About Us"
                url="/about"
              />
            </Col>
            <Col md={6}>
              <img
                src={toAbsoluteUrl("/media/illustrations/homeflow.svg")}
                alt=""
                className="img-fluid mt-4 mt-md-0"
              />
            </Col>
          </Row>
        </Container>
      </section>
      {/* brand section statt */}
      <section className="brand-section bg-primary text-white">
        <SectionHeading isDark={true} sectionTitle="Our Brands" />
        <Container>
          <Row className="justify-content-center">
            {isBrandsLoading && (
              <div className="text-center mt-3">
                <div className="spinner-border text-light" role="status"></div>
              </div>
            )}
            {brands.map((item: any) => {
              return (
                <>
                  <Col xs={6} md={6} lg={"auto"}>
                    <a
                      className="text-white cursor-pointer"
                      onClick={() => {
                        localStorage.setItem("brandId", item.id);
                        navigate(`/brand/${item.slug}`);
                      }}
                    >
                      <div className="text-center home-brand mt-50px">
                        <div className="mb-20px mx-auto">
                          <img
                            src={item.brand_image}
                            srcSet={item.brand_image}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <h5>{item.name}</h5>
                      </div>
                    </a>
                  </Col>
                </>
              );
            })}
          </Row>
        </Container>
      </section>
      {/* brand section end */}

      <section
        className="text-white position-relative counter-section text-center"
        style={{
          // backgroundImage: `url(${process.env.PUBLIC_URL + '/media/video.gif'})`
          backgroundImage: `url(${toAbsoluteUrl("/media/video.gif")})`,
          // {toAbsoluteUrl('/media/illustrations/heroBgVector.svg')}
        }}
      >
        {/* <video autoPlay muted loop id="myVideo">
                    <source src={toAbsoluteUrl('/media/video.mp4')} type="video/mp4" />
                    Your browser does not support HTML5 video.
                </video> */}
        <div className="counter-overlay"></div>
        <Container className="position-relative">
          <Row className="justify-content-center">
            <Col xs="12" lg="auto">
              <div className="counter">
                <NumberAnimated value={10000} />
                <div className="sep mx-auto my-1"></div>
                <h6 className="mb-0 fw-700 lora font-20">Farmers</h6>
              </div>
            </Col>
            <Col xs="12" lg="auto" className="center-count">
              <div className="counter">
                <NumberAnimated value={7000} />
                <div className="sep mx-auto my-1"></div>
                <h6 className="mb-0 fw-700 lora font-20">Small Retailers</h6>
              </div>
            </Col>
            <Col xs="12" lg="auto">
              <div className="counter">
                <NumberAnimated value={4000} />
                <div className="sep mx-auto my-1"></div>
                <h6 className="mb-0 fw-700 lora font-20">Daily Deliveries</h6>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* testimonial list section start */}
      <section className="section-padding">
        <SectionHeading sectionTitle="What our People speak about us" />
        <Container>
          <Row>
            {testimonial.length === 0 ? (
              <div className="mt-3">
                {isTestimonialLoading ? <IsLoading /> : <NoRecords />}
              </div>
            ) : (
              testimonial.map((item: any) => {
                return (
                  <>
                    {item.testimonial_url ? (
                      <>
                        <Col md="6" lg="4" className="mt-30px">
                          <a className="text-dark cursor-pointer">
                            <TeastimonialCard
                              name={item.person_name}
                              category={item.testimonial_from}
                              path="/media/images/videoblog.png"
                              pathLogo={item.person_logo_thumbnail}
                              subTitle={item.Sub_title}
                              isVideo={true}
                              url={item.testimonial_url}
                            />
                          </a>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col md="6" lg="4" className="mt-30px">
                          <a className="text-dark cursor-pointer">
                            <TeastimonialCard
                              name={item.person_name}
                              description={item.testimonial_text}
                              category={item.testimonial_from}
                              pathLogo={item.person_logo_thumbnail}
                              subTitle={item.Sub_title}
                            />
                          </a>
                        </Col>
                      </>
                    )}
                  </>
                );
              })
            )}
            {loadMore && testimonial.length > 0 && (
              <Col xs="12" className="text-center mt-30px">
                <Button
                  color="primary"
                  outline
                  className="btn-rounded"
                  onClick={() => navigate("/testimonials")}
                >
                  Show More
                </Button>
              </Col>
            )}
          </Row>
        </Container>
      </section>
      {/* testimonial list section end */}
    </>
  );
};

export default MasterLayout(Home);
