import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Form, useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  goToTop,
  MOBILE_MAX_LENGTH,
} from "../../../_eaFruitsCms/helpers/CommonHelpers";
import { postRequest } from "../../../_eaFruitsCms/https/apiCall";
import { APPLICATION } from "../../../_eaFruitsCms/https/ApiRoutes";
import ErrorHandler from "../../../_eaFruitsCms/validation/ErrorHandler";
import { validateEmail } from "../../../_eaFruitsCms/validation/validation";
import { toAbsoluteUrl } from "../../../helper/AssetHelpers";
import { KTSVG } from "../../../helper/components/KTSVG";
import MasterLayout from "../../../layout/masterlayout";
import CustomReCaptcha from "../../components/CustomReCaptcha";
import { successToast } from "../../../_eaFruitsCms/helpers/CommonHelpers";

const ApplyNow = (props: any) => {
  const navigate = useNavigate();

  const [captchaValid, setCaptchaValid] = useState<boolean>(false);
  const [captchaReset, setCaptchaReset] = useState<boolean>(false);

  const initialRow: any = {
    firstname: "",
    lastname: "",
    email: "",
    phone_no: "",
    address: "",
    resume: "",
  };

  const initialValidation: any = {
    firstname: "",
    lastname: "",
    email: "",
    phone_no: "",
    address: "",
    resume: "",
  };
  const [isOpen, setIsOpen] = useState(false);
  const toggleCategory = () => setIsOpen(!isOpen);
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const [details, setDetails] = useState<any>(initialRow);
  const [errorMessage, setErrorMessage] = useState("");
  const [sizerMessage, setSizeMessage] = useState("");
  const [detailsValidation, setDetailsValidation] =
    useState<any>(initialValidation);
  const [buttonText, setButtonText] = useState("Submit");

  useEffect(() => {
    goToTop();
  }, []);

  const handleChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name;
    let inputValue = e.target ? e.target.value : e.value;

    setDetailsValidation({ ...detailsValidation, [inputName]: "" });

    switch (inputName) {
      case "firstname":
        setDetails({
          ...details,
          ["firstname"]: e.target.value.replace(/[^a-zA-Z\s]/g, ""),
        });
        break;
      case "lastname":
        setDetails({
          ...details,
          ["lastname"]: e.target.value.replace(/[^a-zA-Z\s]/g, ""),
        });
        break;
      case "email":
        setDetails({ ...details, ["email"]: inputValue });
        setErrorMessage("");
        break;
      case "phone_no":
        const re = /^[0-9\b]+$/;
        if (inputValue === "" || re.test(inputValue)) {
          setDetails({ ...details, ["phone_no"]: e.target.value });
        }
        break;
      case "address":
        setDetails({ ...details, ["address"]: inputValue });
        break;
      case "resume":
        if (e.target.files[0].size > 5000000) {
          setSizeMessage("Maximum allowed file size is 5mb");
          setDetails({ ...details, ["resume"]: e.target.files[0] });
          // > 5mb file will do not allow
        } else {
          setSizeMessage("");
          setDetails({ ...details, ["resume"]: e.target.files[0] });
        }
        break;
    }
  };

  const validateForm = () => {
    let validations = { ...detailsValidation };
    let isValid = true;

    if (details.firstname == "") {
      validations.firstname = "is-invalid";
      isValid = false;
    }

    if (details.lastname == "") {
      validations.lastname = "is-invalid";
      isValid = false;
    }

    if (details.email === "") {
      validations.email = "is-invalid";
      isValid = false;
    } else {
      if (!validateEmail(details.email)) {
        setDetailsValidation({
          ...detailsValidation,
          ["email"]: "is-invalid",
        });
        isValid = false;
      }
    }

    if (details.phone_no == "") {
      validations.phone_no = "is-invalid";
      isValid = false;
    }

    if (details.address == "") {
      validations.address = "is-invalid";
      isValid = false;
    }

    if (
      details.resume == "" ||
      sizerMessage == "Maximum allowed file size is 1mb"
    ) {
      validations.resume = "is-invalid";
      isValid = false;
    }

    if (!isValid) {
      setCaptchaReset(!captchaReset);
    }

    setDetailsValidation(validations);
    return isValid;
  };

  const submitForm = () => {
    const isValid = validateForm();
    if (isValid) {
      let ApplicationData = new FormData();
      const detailsInfo = { ...details };

      Object.entries(detailsInfo).map((object: any) => {
        return ApplicationData.append(object[0], object[1]);
      });

      setButtonText("Please Wait...");

      postRequest(APPLICATION, ApplicationData, navigate, false).then(
        (res: any) => {
          if (res.status === 201) {
            {
              //   toggleModal();
              successToast(
                "Thank you, your job application has been received successfully!"
              );
              setDetails(initialRow);
              setErrorMessage("");
            }
          } else {
            setErrorMessage("Application with this email already exists.");
          }
          setCaptchaReset(!captchaReset);
          setButtonText("Submit");
        }
      );
    }
  };

  return (
    <>
      <section className="section-padding blog-banner about-banner d-flex align-items-center">
        <img
          src={toAbsoluteUrl("/media/images/heroApply.jpg")}
          srcSet={toAbsoluteUrl("/media/images/heroApply@2x.jpg 2x")}
          className="blogheroimg"
        />
        <Container>
          <Row className="justify-content-center">
            <Col lg="10" xl="9">
              <h1 className="text-center text-white fw-700 position-relative blog-banner-title">
                Apply Now
              </h1>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Apply now start */}
      <section className="section-padding pt-0 apply-now-section position-relative">
        <Container>
          <Row className="justify-content-center">
            <Col lg="7" xl="6">
              <div className="bg-white apply-form rounded card border-0">
                <Form>
                  <Row>
                    <Col sm="12" md="12" lg="12">
                      <FormGroup>
                        <Label for="fname">
                          First Name<span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="fname"
                          name="firstname"
                          placeholder="Type here..."
                          className={clsx(
                            "form-control",
                            detailsValidation.firstname
                          )}
                          value={details.firstname}
                          onChange={(e) => handleChange(e, "firstname")}
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="12" lg="12">
                      <FormGroup>
                        <Label for="lname">
                          Last Name<span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="lname"
                          name="lastname"
                          placeholder="Type here..."
                          className={clsx(
                            "form-control",
                            detailsValidation.lastname
                          )}
                          value={details.lastname}
                          onChange={(e) => handleChange(e, "lastname")}
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="12" lg="12">
                      <FormGroup>
                        <Label for="email">
                          Email<span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="email"
                          name="email"
                          placeholder="Type here..."
                          className={clsx(
                            "form-control",
                            detailsValidation.email
                          )}
                          value={details.email}
                          onChange={(e) => handleChange(e, "email")}
                          onBlur={(e) => {
                            if (!validateEmail(e.target.value)) {
                              setDetailsValidation({
                                ...detailsValidation,
                                ["email"]: "is-invalid",
                              });
                            }
                          }}
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="12" lg="12">
                      <FormGroup>
                        <Label for="phone_no">
                          Phone Number<span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="phone_no"
                          maxLength={MOBILE_MAX_LENGTH}
                          type="text"
                          name="phone_no"
                          placeholder="Type here..."
                          className={clsx(
                            "form-control",
                            detailsValidation.phone_no
                          )}
                          value={details.phone_no}
                          onChange={(e) => handleChange(e, "phone_no")}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup>
                        <Label for="address">
                          Address<span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="address"
                          name="address"
                          placeholder="Street Address"
                          className={clsx(
                            "form-control",
                            detailsValidation.address
                          )}
                          value={details.address}
                          onChange={(e) => handleChange(e, "address")}
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <FormGroup>
                      <Label for="resume">
                        Resume<span className="text-danger">*</span>
                      </Label>
                      <div className="position-relative cursor-pointer">
                        <Label
                          for="resume"
                          className={clsx(
                            "form-control file-input text-truncate pe-5",
                            detailsValidation.resume
                          )}
                        >
                          <span className="text-muted">
                            {details.resume
                              ? details.resume.name
                              : "Attach Document"}
                          </span>
                        </Label>
                        <Input
                          id="resume"
                          name="resume"
                          placeholder="Attach Document"
                          className={clsx(
                            "invisible position-absolute",
                            detailsValidation.resume
                          )}
                          onChange={(e) => handleChange(e, "resume")}
                          accept="application/pdf, image/*, application/msword"
                          type="file"
                        />
                      </div>
                    </FormGroup>
                    <ErrorHandler errorMessage={sizerMessage} />
                    <ErrorHandler errorMessage={errorMessage} />
                  </Row>
                  <div className=" mt-3">
                    <CustomReCaptcha
                      setCaptchaValid={setCaptchaValid}
                      captchaReset={captchaReset}
                    />
                  </div>
                  <Button
                    className="btn-rounded mt-3"
                    color="primary"
                    outline
                    disabled={!captchaValid || buttonText !== "Submit"}
                    onClick={submitForm}
                  >
                    {buttonText}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Apply now end*/}

      {/* Successful message alert */}
      {/* <Modal
        isOpen={modal}
        toggle={toggleModal}
        centered
        size={"lg"}
        // modalTransition={{ timeout: 700 }}
      >
        <ModalHeader
          toggle={toggleModal}
          className="bg-primary text-white"
        ></ModalHeader>
        <ModalBody className="text-center px-lg-5 py-4 px-4 py-lg-5">
          <KTSVG
            path="/media/icons/ic_check.svg"
            className="ic me-2 mb-20px text-dark"
          />
          <h3 className="fw-700 font-20">Message Sent Successful</h3>
          <p>
            Your message has been sent successfuly, I hope to respond within 24
            hours. You can also contact us through links can be found below!
          </p>
        </ModalBody>
      </Modal> */}
    </>
  );
};

export default MasterLayout(ApplyNow);
