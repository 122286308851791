import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import {
  goToTop,
  nextOffset,
} from "../../../_eaFruitsCms/helpers/CommonHelpers";
import { getRequest } from "../../../_eaFruitsCms/https/apiCall";
import { TESTIMONIALS_LIST } from "../../../_eaFruitsCms/https/ApiRoutes";
import { metaImage } from "../../../helper/AssetHelpers";
import TeastimonialCard from "../../../layout/components/cards/testimonialCard";
import SectionHeading from "../../../layout/components/headings/sectionHeading";
import MasterLayout from "../../../layout/masterlayout";
import CustomHelmet from "../../components/CustomHelmet";
import CustomLoader from "../../components/CustomLoader";

const clientImage = [
  "/media/images/client1.png",
  "/media/images/client2.png",
  "/media/images/client3.png",
  "/media/images/client4.png",
  "/media/images/client5.png",
  "/media/images/client6.png",
];
const settings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: "60px",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "40px",
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "30px",
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "40px",
      },
    },
  ],
};
const Testimonials = (props: any) => {
  const navigate = useNavigate();

  const [testimonial, setTestimonial] = useState<any>([]);
  const [limit, setLimit] = useState<any>(6);
  const [displayLoader, setDisplayLoader] = useState<boolean>(false);
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [currentOffset, setCurrentOffSet] = useState<any>("");
  const [nextUrl, setNextUrl] = useState<any>("");

  useEffect(() => {
    goToTop(setDisplayLoader);
  }, []);

  useEffect(() => {
    fetchTestimonial().then();
  }, [currentOffset]);

  const fetchTestimonial = async () => {
    setDisplayLoader(true);
    const response = await getRequest(
      TESTIMONIALS_LIST + `?limit=${limit}&offset=${currentOffset}`,
      navigate,
      false
    );
    setNextUrl(response.next);
    setLoadMore(false);

    if (response.next && !["", null, "null"].includes(response.next)) {
      setLoadMore(true);
    }

    setTestimonial(testimonial.concat(response.results));
    setDisplayLoader(false);
  };

  return (
    <>
      <CustomHelmet
        title={`Testimonials | EA Foods`}
        metaDescription={`Read these testimonials and see what our Customers have to say. We are grateful to those customers who have been good enough to recommend us to friends.`}
        imagePath={metaImage}
      />
      {displayLoader && <CustomLoader />}
      {/* testimonial hero section start */}
      <section className="section-padding bg-light">
        <SectionHeading
          sectionTitle="What our People speak about us"
          sectionPara="See What our Customers have to say... We are grateful to those customers who
          have been good enough to recommend us to friends."
        />
      </section>

      {/* testimonial hero section end */}
      {/* testimonial list section start */}
      <section className="success-stories-section section-padding">
        <Container>
          <Row>
            {testimonial.map((item: any) => {
              return (
                <>
                  {item.testimonial_url ? (
                    <>
                      <Col md="6" lg="4" className="mt-30px">
                        <a href="JAVASCRIPT:void(0)" className="text-dark">
                          <TeastimonialCard
                            name={item.person_name}
                            category={item.testimonial_from}
                            path="/media/images/videoblog.png"
                            pathLogo={item.person_logo_thumbnail}
                            subTitle={item.Sub_title}
                            isVideo={true}
                            url={item.testimonial_url}
                          />
                        </a>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col md="6" lg="4" className="mt-30px">
                        <a
                          href="JAVASCRIPT:void(0)"
                          className="text-dark cursor-pointer"
                        >
                          <TeastimonialCard
                            name={item.person_name}
                            description={item.testimonial_text}
                            category={item.testimonial_from}
                            pathLogo={item.person_logo_thumbnail}
                            subTitle={item.Sub_title}
                          />
                        </a>
                      </Col>
                    </>
                  )}
                </>
              );
            })}
            <Col xs="12" className="text-center mt-30px">
              {loadMore && (
                <Button
                  color="primary"
                  outline
                  className="btn-rounded"
                  onClick={() => {
                    setCurrentOffSet(nextOffset(nextUrl));
                  }}
                >
                  Load More
                </Button>
              )}
            </Col>
          </Row>
        </Container>
      </section>
      {/* testimonial list section end */}
      {/* client carousel start */}
      {/* client carousel end */}

      {/* <section className="section-padding pt-0">
        <SectionHeading
          sectionTitle="Our Customers"
          sectionPara="As more and more people are turning to organic lifestyles & trying improve their health As more and more people"
        />
        <Container>
          <div>
            <Slider className="mt-50px" {...settings}>
              {clientImage.map((clientImage) => (
                <div className="client-logo rounded border d-flex align-items-center">
                  <img
                    src={toAbsoluteUrl(clientImage)}
                    alt=""
                    className="img-fluid  rounded"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </Container>
      </section> */}
    </>
  );
};

export default MasterLayout(Testimonials);
