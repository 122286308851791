import React from "react"
import { useNavigate } from "react-router-dom"
import { Button, Col, Container, Row, } from "reactstrap"
import { toAbsoluteUrl } from "../../helper/AssetHelpers"

const NotRespond = (props: any) => {
    const navigate = useNavigate();
    return (
        <>
            <section className="section-padding error-page">
                <Container>
                    <Row className="justify-content-center">
                        <Col xs='10'>
                            <div className="text-center p">
                                <img
                                    src={toAbsoluteUrl(
                                        "/media/icons/ic_not_respond.svg"
                                    )}
                                    alt=""
                                    className="img-fluid w-56px mb-4"
                                />
                                <p className="font-28 fw-500">Oops, something went wrong!  </p>
                                <p className="pb-10px mb-2">Please go to the previous page and try again!</p>
                                <Button color="primary" className="btn-rounded mb-20px"
                                    onClick={() => {
                                        navigate(-1);
                                    }}
                                >Go Back</Button>
                                <p>If the issue persists, please reach out to our
                                    <a href="JAVASCRIPT:void" className="text-primary"
                                        onClick={() => {
                                            navigate('/contact-us');
                                        }}> Customer Support
                                    </a>.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default NotRespond;
