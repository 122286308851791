import React from "react";
import Helmet from "react-helmet";
import { CURRENT_ENVIRONMENT } from "../../_eaFruitsCms/helpers/CommonHelpers";

const CustomHelmet = ({ title, metaDescription, imagePath }: any) => {
  const currentURL = window.location.href;

  return (
    <Helmet>
      <title>{typeof title !== 'undefined' && title.includes('undefined') ? 'EA Foods' : title}</title>
      {!CURRENT_ENVIRONMENT && (
        <meta name="robots" content={"noindex,nofollow"} />
      )}
      <meta name="description" content={metaDescription} />
      <meta name="og:url" content={currentURL} />
      <meta name="og:type" content={"website"} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={metaDescription} />
      <meta name="og:image" content={imagePath} />
      <meta name="twitter:card" content={`summary_large_image`} />
      <meta name="twitter:domain" content={`eafoods.com`} />
      <meta name="twitter:url" content={currentURL} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={imagePath} />
      <link rel="canonical" href={currentURL.replace(/\/$/, "")} />
    </Helmet>
  );
};

export default CustomHelmet;
