import React, { useState } from "react"
import { Card, CardBody, CardText, CardTitle, Col, Row, } from "reactstrap"
import { toAbsoluteUrl } from "../../../../helper/AssetHelpers"
import Chip from "../../../../layout/components/chip/chip"

type storyCardProps = {
    title: string;
    subtitle?: string;
    name: string;
    lastname?: string;
    description: string;
    className?: string;
    category?: string;
    address: string;
    path: any;
    date: any;
    chipcolor?: string;
    onClick?: any;
    storyType?: string;
};

const StoryCard: React.FC<storyCardProps> = ({
    name,
    lastname,
    title,
    subtitle,
    description,
    category,
    address,
    path,
    date,
    className,
    onClick,
}) => {
    const [imageLoad, setImageLoad] = useState<boolean>(false)

    return (
        <>
            <Card className="bg-white border-0 h-100">
                <Row className="g-md-0 flex-sm-row-reverse justify-content-center h-100">
                    <Col xs="12" sm="8">
                        <CardBody className="h-100 d-flex flex-column py-30px">
                            <CardTitle tag="h5" className="truncate-2 mb-10">
                                {name} {lastname}
                                {/* <span className="font-16 fw-400 inter"> - Sub Title</span> */}
                                <span className="font-16 fw-400 inter"> - {subtitle}</span>
                            </CardTitle>
                            <CardTitle tag="h5" className="truncate-2 mb-1">
                                {title}
                            </CardTitle>
                            <CardText className="truncate-3 mb-10">{description}</CardText>
                            <CardText className="truncate-3 mb-10">{address}</CardText>
                            <div className="d-flex justify-content-between mt-auto">
                                {(() => {
                                    switch (category) {
                                        case "Farmer":
                                            return (
                                                <Chip chipText="Farmer Stories" className="success" />
                                            );
                                        case "Customer":
                                            return (
                                                <Chip chipText="Customers Stories" className="warning" />
                                            );
                                        case "Employee":
                                            return (
                                                <Chip chipText="Employee Stories" className="primary" />
                                            );
                                        default:
                                            return null;
                                    }
                                })()}

                                <div className="text-muted">{date}</div>
                            </div>
                        </CardBody>
                    </Col>
                    <Col xs="12" sm="4" className="text-center">
                        <div className="position-relative flex-grow-1">
                        <img
                            src={toAbsoluteUrl(path)}
                            alt=""
                            className="w-100 img-fluid story-avtar rounded-start"
                            onLoad={() => {
                                setImageLoad(true)
                            }}
                        />
                            {!imageLoad &&
                                <span className="skeleton-box"></span>
                            }
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default StoryCard;
