import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const CustomPagination = (props: any) => {
  const { pageState, setPageState, scrollOnClick } = props;

  let totalCount: any = Math.ceil(pageState.count / pageState.limit);

  let numberCount: any = [];
  for (let index = 1; index <= totalCount; index++) {
    numberCount.push(index);
  }

  const numberClick = (val: any, index: any) => {
    if (val) {
      let newOffset = index * pageState.limit;

      setPageState((preValue: any) => ({
        ...preValue,
        selected: val - 1,
        currentOffset: newOffset,
      }));
    }
  };

  const previous = () => {
    if (pageState.previous !== null) {
      const queryParams = new URLSearchParams(pageState.previous);
      const newOffset = queryParams.get("offset");

      setPageState((preValue: any) => ({
        ...preValue,
        selected: pageState.selected - 1,
        currentOffset: newOffset,
      }));
    }
  };

  const nextPage = () => {
    if (pageState.next !== null) {
      const queryParams = new URLSearchParams(pageState.next);
      const newOffset = queryParams.get("offset");

      setPageState((preValue: any) => ({
        ...preValue,
        selected: pageState.selected + 1,
        currentOffset: newOffset,
      }));
    }
  };

  return (
    <>
      {numberCount.length > 1 && <Pagination aria-label="Page navigation example" className="mt-50px">
        <PaginationItem disabled={pageState.previous === null}>
          <PaginationLink
            previous
            className="previous"
            onClick={() => {
              previous();
              scrollOnClick();
            }}
          ></PaginationLink>
        </PaginationItem>
        {numberCount.map((item: any, index: any) => (
          <PaginationItem
            className={`${index === pageState.selected ? "active" : ""}`}
          >
            <PaginationLink
              key={item}
              onClick={() => {
                numberClick(item, index);
                scrollOnClick();
              }}
              href="JAVASCRIPT:void(0)"
            >
              {item}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem disabled={pageState.next === null}>
          <PaginationLink
            next
            className="next"
            onClick={() => {
              nextPage();
              scrollOnClick();
            }}
          ></PaginationLink>
        </PaginationItem>
      </Pagination>}
    </>
  );
};

export default CustomPagination;
