import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { Col, Container, Row } from "reactstrap";
import { goToTop } from "../../../_eaFruitsCms/helpers/CommonHelpers";
import MasterLayout from "../../../layout/masterlayout";

const TermsOfUse = (props: any) => {
  useEffect(() => {
    goToTop();
  }, []);
  return (
    <>
      <Helmet>
        <title>Terms of Use | EA Foods</title>
        <meta
          name="description"
          content={`In order to use EA Foods, the User is concluding a use agreement with the Provider, which is subject to these terms and conditions of use.`}
        />
      </Helmet>
      <section className="section-padding bg-light">
        <Container>
          <Row className="g-0 justify-content-center">
            <Col md="auto">
              <h2>Terms of Use</h2>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="mt-50px section-padding pt-0">
        <Container>
          <Row className="">
            <Col md="12">
              <div className="mb-20px">
                <h6 className="mb-10 fw-700">1. Introduction.</h6>
                <p>
                  Welcome to EA Foods (“Company”, “we”, “our”, “us”)! <br />
                  These terms and Conditions govern your use of the EA Foods
                  website and any services offered through the website. By using
                  the website, you agree to be bound by these Terms and
                  Conditions.
                </p>
                <p>
                  Your agreement with us includes these Terms and our Privacy
                  Policy (“Agreements”). You acknowledge that you have read and
                  understood Agreements, and agree to be bound by them.
                </p>
                <p>
                  If you do not agree with (or cannot comply with) Agreements,
                  then you may not use the Service, but please let us know by
                  emailing at{" "}
                  <a href="mailto:hello@eafoods.com" className="text-primary">
                    hello@eafoods.com
                  </a>{" "}
                  so we can try to find a solution. These Terms apply to all
                  visitors, users and others who wish to access or use Service.
                </p>
              </div>
              <div className="mb-20px">
                <h6 className="mb-10 fw-700">2. Membership Eligibility</h6>
                <p>
                  The website and its services are intended for users who are 18
                  years of age or older and reside in the jurisdiction where the
                  website is being used.
                </p>
                <p>
                  When you create an account with us, you guarantee that the
                  information you provide us is accurate, complete, and current
                  at all times. Inaccurate, incomplete, or obsolete information
                  may result in the immediate termination of your account on
                  Service.
                </p>
                <p>
                  You are responsible for maintaining the confidentiality of
                  your account and password, including but not limited to the
                  restriction of access to your computer and/or account. You
                  agree to accept responsibility for any and all activities or
                  actions that occur under your account and/or password, whether
                  your password is with our Service or a third-party service.
                  You must notify us immediately upon becoming aware of any
                  breach of security or unauthorised use of your account.
                </p>
                <p>
                  You may not use as a username the name of another person or
                  entity or that is not lawfully available for use, a name or
                  trademark that is subject to any rights of another person or
                  entity other than you, without appropriate authorisation. You
                  may not use as a username any name that is offensive, vulgar
                  or obscene.
                </p>
                <p>
                  We reserve the right to refuse service, terminate accounts,
                  remove or edit content, or cancel orders at our sole
                  discretion.
                </p>
              </div>
              <div className="mb-20px">
                <h6 className="mb-10 fw-700">
                  3. Account and Registration Obligations
                </h6>
                <p>
                  Creating an account with EA Foods is an important step in
                  using our website and accessing the full range of services
                  that we offer. When you register for an account you agree to
                  the following obligations;
                </p>
                <p>
                  You agree to provide accurate, complete and up-to-date
                  information as prompted by the account registration form, and
                  to update such information as necessary to keep it accurate
                  and up-to-date.
                </p>
                <p>
                  You are responsible for maintaining the confidentiality of
                  your account and password and for restricting access to your
                  computer. You agree to accept responsibility for all
                  activities that occur under your account.
                </p>
                <p>
                  You acknowledge and agree that the website and its content,
                  including but not limited to text, graphics, software, and
                  logos, are the proprietary property of EA Foods, and are
                  protected by applicable intellectual property laws.
                </p>
              </div>
              <div className="mb-20px">
                <h6 className="mb-10 fw-700">4. Communications</h6>
                <p>
                  By using our Service, you agree to subscribe to newsletters,
                  marketing or promotional materials and other information we
                  may send. However, you may opt out of receiving any, or all,
                  of these communications from us by following the unsubscribe
                  link or by emailing at{" "}
                  <a href="mailto:hello@eafoods.com" className="text-primary">
                    hello@eafoods.com
                  </a>
                </p>
              </div>
              <div className="mb-20px">
                <h6 className="mb-10 fw-700">5. Indemnity and Release</h6>
                <p>
                  You agree to indemnify, defend, and hold harmless EA Foods,
                  its officers, directors, employees, agents, licensors and
                  suppliers from and against all losses, expenses, damages and
                  costs, including reasonable attorneys’ fees, resulting from
                  any violation of these Terms and Conditions or any activity
                  related to your account.
                </p>
              </div>
              <div className="mb-20px">
                <h6 className="mb-10 fw-700">
                  6. Complaint against Objectionable Content
                </h6>
                <p>
                  We respect the intellectual property rights of others. It is
                  our policy to respond to any claim that Content posted on
                  Service infringes on the copyright or other intellectual
                  property rights (“Infringement”) of any person or entity.
                </p>
                <p>
                  If you are a copyright owner, or authorised on behalf of one,
                  and you believe that the copyrighted work has been copied in a
                  way that constitutes copyright infringement, please submit
                  your claim via email to{" "}
                  <a href="mailto:hello@eafoods.com" className="text-primary">
                    hello@eafoods.com
                  </a>
                  , with the subject line: “Copyright Infringement” and include
                  in your claim a detailed description of the alleged
                  Infringement as detailed below, under “DMCA Notice and
                  Procedure for Copyright Infringement Claims”
                </p>
                <p>
                  You may be held accountable for damages (including costs and
                  attorneys’ fees) for misrepresentation or bad-faith claims on
                  the infringement of any Content found on and/or through
                  Service on your copyright.
                </p>
                <p>
                  To make a complaint, please send an email to{" "}
                  <a href="mailto:hello@eafoods.com" className="text-primary">
                    hello@eafoods.com
                  </a>{" "}
                  including a detailed description of the objectionable content
                  and the URL where it can be found. We will make every effort
                  to promptly investigate and take appropriate action, including
                  but not limited to removing the content in question.
                </p>
                <p>
                  Please note that while we will make every effort to resolve
                  the issue in a timely manner, EA Foods does not guarantee that
                  it will be able to remove all objectionable content, and it is
                  possible that some content may remain accessible through the
                  website despite our best efforts.
                </p>
              </div>
              <div className="mb-20px">
                <h6 className="mb-10 fw-700">
                  7. Alteration of Service or Amendments to the Conditions
                </h6>
                <p>
                  We may amend Terms at any time by posting the amended terms on
                  this site. It is your responsibility to review these Terms
                  periodically.
                </p>
                <p>
                  Your continued use of the Platform following the posting of
                  revised Terms means that you accept and agree to the changes.
                  You are expected to check this page frequently so you are
                  aware of any changes, as they are binding on you.
                </p>
                <p>
                  By continuing to access or use our Service after any revisions
                  become effective, you agree to be bound by the revised terms.
                  If you do not agree to the new terms, you are no longer
                  authorised to use Service.
                </p>
              </div>
              <div className="mb-20px">
                <h6 className="mb-10 fw-700">8. Limitation of Liability</h6>
                <p>
                  EA Foods shall not be liable for any damages of any kind
                  arising from the use of the website or its services, including
                  but not limited to direct, indirect, incidental, punitive and
                  consequential damages.
                </p>
              </div>
              <div className="mb-20px">
                <h6 className="mb-10 fw-700">9. Disclaimer</h6>
                <p>
                  These services are provided by the company on an “as is” and
                  “as available” basis. company makes no representations or
                  warranties of any kind, express or implied, as to the
                  operation of their services, or the information, content or
                  materials included therein. you expressly agree that your use
                  of these services, their content, and any services or items
                  obtained from us is at your sole risk.
                </p>
                <p>
                  Neither company nor any person associated with the company
                  makes any warranty or representation with respect to the
                  completeness, security, reliability, quality, accuracy, or
                  availability of the services. without limiting the foregoing,
                  neither company nor anyone associated with company represents
                  or warrants that the services, their content, or any services
                  or items obtained through the services will be accurate,
                  reliable, error-free, or uninterrupted, that defects will be
                  corrected, that the services or the server that makes it
                  available are free of viruses or other harmful components or
                  that the services or any services or items obtained through
                  the services will otherwise meet your needs or expectations.
                </p>
                <p>
                  Company hereby disclaims all warranties of any kind, whether
                  express or implied, statutory, or otherwise, including but not
                  limited to any warranties of merchantability,
                  non-infringement, and fitness for particular purpose.
                </p>
                <p>
                  The foregoing does not affect any warranties which cannot be
                  excluded or limited under applicable law.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MasterLayout(TermsOfUse);
