import React from "react"
import ReactPlayer from "react-player"
import { Card, CardBody, CardFooter, CardText, CardTitle, Col, Row, } from "reactstrap"
import { toAbsoluteUrl } from "../../../helper/AssetHelpers"
import Chip from "../chip/chip"

type testimonialCardProps = {
  name: string;
  subTitle?: string;
  description?: string;
  className?: string;
  category: string;
  path?: any;
  pathLogo?: any;
  date?: any;
  onClick?: any;
  isVideo?: boolean;
  url?: string;
};
const TeastimonialCard: React.FC<testimonialCardProps> = ({
  isVideo,
  pathLogo,
  name,
  subTitle,
  description,
  category,
  date,
  path,
  className,
  onClick,
  url,
}) => {
  return (
    <>
      {isVideo ? (
        <>
          <Card
            className={`border-0 position-relative overflow-hidden bg-white text-white h-100 tm-card tm-video ${className}`}
          >
            <CardBody className="position-relative overflow-hidden">
              <div className="video-overlay position-absolute"></div>
              <div className="position-absolute img-videoplaceholder">
                <ReactPlayer
                  controls={true}
                  width="100%"
                  height="100%"
                  url={url}
                />
              </div>
              {/* <img src={toAbsoluteUrl(path)} alt="" className='position-absolute img-videoplaceholder' /> */}
              {/* <span className='position-absolute icon-video '>
                                    <img src={toAbsoluteUrl('/media/images/img_play.svg')} alt="" className='pulse' />
                                </span> */}
            </CardBody>
            <CardFooter className="bg-white text-dark">
              <Row>
                <Col xs="auto">
                  <div className="border rounded-3 d-flex align-items-center justify-content-center tm-logo">
                    <img src={toAbsoluteUrl(pathLogo)} alt="" />
                  </div>
                </Col>
                <Col>
                  <CardTitle tag="h4" className="font-20 text-uppercase mb-1">
                    {name}
                  </CardTitle>
                  <div className="font-16 fw-400 inter mb-10px">{subTitle}</div>
                  {(() => {
                    switch (category) {
                      case "Farmer":
                        return <Chip chipText="Farmer" className="primary" />;
                      case "Customer":
                        return <Chip chipText="Customer" className="success" />;
                      case "Employee":
                        return <Chip chipText="Employee" className="primary" />;
                      default:
                        return null;
                    }
                  })()}
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </>
      ) : (
        <>
          <Card
            className={`border-0 position-relative bg-primary text-white tm-card ${className}`}
          >
            <CardBody className=" ">
              <CardText className="mb-10">{description}</CardText>
            </CardBody>
            <CardFooter className="bg-white text-dark">
              <Row>
                <Col xs="auto">
                  <div className="rounded-4 d-flex align-items-center justify-content-center tm-logo">
                    <img src={toAbsoluteUrl(pathLogo)} alt="" className="rounded-4 img-fluid w-100" />
                  </div>
                </Col>
                <Col>
                  <CardTitle tag="h4" className="font-20 text-uppercase mb-1">
                    {name}
                  </CardTitle>
                  <div className="font-16 fw-400 inter mb-10px">{subTitle}</div>
                  {(() => {
                    switch (category) {
                      case "Farmer":
                        return <Chip chipText="Farmer" className="primary" />;
                      case "Customer":
                        return <Chip chipText="Customer" className="success" />;
                      case "Employee":
                        return <Chip chipText="Employee" className="primary" />;
                      default:
                        return null;
                    }
                  })()}
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </>
      )}
    </>
  );
};

export default TeastimonialCard;
