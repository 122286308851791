import { animated, useSpring } from "react-spring"
import { numberFormat } from "../../_eaFruitsCms/helpers/CommonHelpers"

const NumberAnimated = ({ value }: any) => {
    const { number } = useSpring({
        from: { number: 0 },
        number: value,
        delay: 100,
        config: { mass: 1, tension: 20, friction: 10 }
    })

    return <animated.div className="count-num mb-1">
        {
            number.to((n) => numberFormat(n.toFixed(0)).displayFormat + "+")
        }
    </animated.div>
}

export default NumberAnimated
