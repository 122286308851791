import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import {
  NavLink as RNavLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import {
  dateFormat,
  goToTop,
} from "../../../_eaFruitsCms/helpers/CommonHelpers";
import { getRequest } from "../../../_eaFruitsCms/https/apiCall";
import { BLOG_STORIES_LIST } from "../../../_eaFruitsCms/https/ApiRoutes";
import { toAbsoluteUrl } from "../../../helper/AssetHelpers";
import HeroBanner from "../../../layout/components/banners/heroBanner";
import SectionHeading from "../../../layout/components/headings/sectionHeading";
import SectionHeadingStart from "../../../layout/components/headings/sectionHeadingStart";
import NoRecords from "../../../layout/components/noRecords/noRecords";
import MasterLayout from "../../../layout/masterlayout";
import CustomLoader from "../../components/CustomLoader";
import StoryCard from "../successStories/components/storyCard";
import IsLoading from "../../components/Loader";

const CustomerServices = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [stories, setStories] = useState<any>([]);
  const [limit, setLimit] = useState<any>(2);
  const [displayLoader, setDisplayLoader] = useState<boolean>(false);
  const [loadMore, setLoadMore] = useState<boolean>(false);

  useEffect(() => {
    goToTop(setDisplayLoader);
    fetchCustomerStories().then();
  }, []);

  const fetchCustomerStories = async () => {
    setDisplayLoader(true);
    const response = await getRequest(
      BLOG_STORIES_LIST +
        `?limit=${limit}&usertype=Customer&ordering=-updated_at`,
      navigate,
      false
    );

    setLoadMore(false);

    if (response.next && !["", null, "null"].includes(response.next)) {
      setLoadMore(true);
    }

    setStories(response.results);
    setDisplayLoader(false);
  };

  return (
    <>
      <Helmet>
        <title>Services to Customers | EA Foods</title>
        <meta
          name="description"
          content={`EA foods provide a wide range of the highest quality fresh and exotic produce directly from farmers to the customers with exceptional customer service 24/7.`}
        />
      </Helmet>
      {/* {displayLoader && <CustomLoader />} */}
      <HeroBanner
        heroTitle="Our Services"
        heroDesc="EA Foods services aim at elevating the supply chain to new heights through the provision of high quality products and services to support the growth and success of smallholder farmers and small retailers from different parts of Tanzania."
        path="/media/images/heroservices.jpg"
        heroImageMobile="/media/images/heroServicesM.jpg"
      />

      {/* nav tabs start */}
      <section className="bg-light">
        <Container>
          <Row>
            <Col>
              <div className="d-none d-md-block my-3 my-md-0">
                <Nav className="nav-light" horizontal="center">
                  <NavItem>
                    <NavLink
                      tag={RNavLink}
                      to="/farmer-services"
                      className={
                        `nav-link` +
                        (location.pathname === "/farmer-services" && " active")
                      }
                    >
                      Services to Farmers
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="/customer-services"
                      className={
                        `nav-link` +
                        (location.pathname === "/customer-services" &&
                          " active")
                      }
                    >
                      Services to Customers
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
              <div className="d-block d-md-none my-3">
                <UncontrolledDropdown>
                  <DropdownToggle nav caret>
                    Services to Customer
                  </DropdownToggle>
                  <DropdownMenu className="w-100">
                    <DropdownItem href="/farmer-services">
                      Services to Farmers
                    </DropdownItem>
                    <DropdownItem href="/customer-services" active>
                      Services to Customer
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* nav tabs end */}

      {/* left side image section start */}
      <section className="section-padding">
        <Container>
          <Row className="leftImageContent align-items-center">
            <Col md={6}>
              <div className="primary-shadow left-shadow rounded">
                <img
                  src={toAbsoluteUrl("/media/images/service3.jpg")}
                  srcSet={toAbsoluteUrl("/media/images/service3@2x.jpg 2x")}
                  className="rounded img-fluid"
                  alt=""
                />
              </div>
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <SectionHeadingStart sectionTitle="Fresh local produce Beyond just Healthy Life" />
              <p className="mb-30px">
                We provide a wide range of the highest quality fresh and exotic
                produce directly from farmers to the customers i.e retailers,
                wholesalers, local customers, supermarkets, restaurants, cafés,
                hotels and exporters accompanied by free and prompt delivery
                with exceptional customer service 24/7.
              </p>
              <p className="mb-0">
                The best part is that there is no such thing as a too large
                order; send us your massive order for projects, schools, or
                camps now and we'll get it delivered in a blink!
              </p>
            </Col>
          </Row>
          {/* row end */}
        </Container>
      </section>
      {/* left side image section start */}

      {/* services section start */}
      <section className="section-padding pt-0">
        <Container>
          <Row>
            <Col md={4} lg={4} className="mt-30px">
              <div className={`bg-white text-center text-dark h-100`}>
                <img
                  src={toAbsoluteUrl(
                    "/media/illustrations/icon_customer_service1.svg"
                  )}
                  className="img-fluid mb-10"
                  alt=""
                />
                <h3 className="font-16 fw-700 mb-10">Free Delivery</h3>
                <p className="mb-0">
                  The free delivery service offers a variety of benefits to
                  customers, including the convenience of ordering fresh produce
                  from the comfort of their own businesses, without the hassle
                  of having to go to the grocery store. In addition, customers
                  can now receive their orders within hours of placing them,
                  ensuring that the produce they receive is always fresh and of
                  the highest quality.
                </p>
              </div>
            </Col>
            <Col md={4} lg={4} className="mt-30px">
              <div className={`bg-white text-center text-dark h-100`}>
                <img
                  src={toAbsoluteUrl(
                    "/media/illustrations/icon_customer_service2.svg"
                  )}
                  className="img-fluid mb-10"
                  alt=""
                />
                <h3 className="font-16 fw-700 mb-10">Quality Fresh Produce</h3>
                <p className="mb-0">
                  Our commitment to quality starts with carefully selecting only
                  the best produce available. All of our fresh produce is
                  carefully sourced directly from farmers who share our
                  commitment to providing fresh, healthy produce.
                </p>
              </div>
            </Col>
            <Col md={4} lg={4} className="mt-30px">
              <div className={`bg-white text-center text-dark h-100`}>
                <img
                  src={toAbsoluteUrl(
                    "/media/illustrations/icon_customer_service3.svg"
                  )}
                  className="img-fluid mb-10"
                  alt=""
                />
                <h3 className="font-16 fw-700 mb-10">After Sale Service</h3>
                <p className="mb-0">
                  Our commitment to customer satisfaction doesn't end at the
                  point of sale, that's why we offer a range of after-sales
                  services to help you make the most of your buying experience.
                  Our friendly customer service team is always happy to assist
                  you, we do customer visits from time to time, don't hesitate
                  to get in touch via email, phone or social media.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* services section end */}

      {/*Customer Success Story start */}
      <section className="section-padding pt-0">
        <SectionHeading
          className="pb-20px"
          sectionTitle="Customer Success Story"
          sectionPara=""
        />
        <Container>
          <Row>
            {displayLoader ? (
              <>
                <Row>
                  <Col xs="6" className="text-center mt-30px">
                    <div
                      className="position-relative"
                      style={{ height: "400px" }}
                    >
                      <span className="skeleton-box"></span>
                    </div>
                  </Col>
                  <Col xs="6" className="text-center mt-30px">
                    <div
                      className="position-relative"
                      style={{ height: "400px" }}
                    >
                      <span className="skeleton-box"></span>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                {stories.length === 0 ? (
                  <NoRecords />
                ) : (
                  stories.map((item: any) => {
                    return (
                      <>
                        <Col md="12" lg="6" className="mt-30px">
                          <a
                            className="text-dark cursor-pointer"
                            onClick={() => {
                              navigate(`/success-stories/${item.slug}`);
                            }}
                          >
                            <StoryCard
                              title={item.story_title}
                              name={item.firstname}
                              lastname={item.lastname}
                              subtitle={item.subtitle}
                              description={item.story_short_description}
                              category="Customer"
                              address={item.location_text}
                              date={dateFormat(item.publish_date).displayDate}
                              path={item.profile_picture}
                            />
                          </a>
                        </Col>
                      </>
                    );
                  })
                )}
                {loadMore && (
                  <Col xs="12" className="text-center mt-30px">
                    <Button
                      color="primary"
                      outline
                      className="btn-rounded"
                      onClick={() =>
                        navigate("/success-stories/customer-stories")
                      }
                    >
                      Show More
                    </Button>
                  </Col>
                )}
              </>
            )}
          </Row>
        </Container>
      </section>
      {/* Customer Success Story end */}
    </>
  );
};

export default MasterLayout(CustomerServices);
