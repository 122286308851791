import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  ListGroup,
  ListGroupItem,
  Row,
  Tooltip,
} from "reactstrap";
import {
  dateFormat,
  goToTop,
  nextOffset,
} from "../../../_eaFruitsCms/helpers/CommonHelpers";
import { getRequest } from "../../../_eaFruitsCms/https/apiCall";
import {
  BLOG_CATEGORY_LIST,
  BLOG_POST_LIST,
  BLOG_STORIES_LIST,
} from "../../../_eaFruitsCms/https/ApiRoutes";
import { metaImage, toAbsoluteUrl } from "../../../helper/AssetHelpers";
import { KTSVG } from "../../../helper/components/KTSVG";
import useDebounce from "../../../helper/components/useDebounce";
import HeroBanner from "../../../layout/components/banners/heroBanner";
import Chip from "../../../layout/components/chip/chip";
import CustomPagination from "../../../layout/components/CustomPagination";
import NoRecords from "../../../layout/components/noRecords/noRecords";
import MasterLayout from "../../../layout/masterlayout";
import CustomHelmet from "../../components/CustomHelmet";
import CustomLoader from "../../components/CustomLoader";
import IsLoading from "../../components/Loader";

const Blogs = () => {
  const navigate = useNavigate();
  const toggleCategory = () => setIsOpen(!isOpen);
  const initialPageState = {
    search: "",
    limit: 6,
    offset: "",
    list: [],
    next: "",
    count: "",
    previous: "",
    isLoading: true,
    currentOffset: "",
    selected: 0,
  };

  const [isOpen, setIsOpen] = useState(false);
  const [categoryList, setCategoryList] = useState<any>([]);
  const [postList, setPostsList] = useState<any>([]);
  const [storyList, setStoryList] = useState<any>([]);
  const [categoryId, setCategoryId] = useState<any>("");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltip, setTooltip] = useState<string>("");
  const [pageState, setPageState] = useState(initialPageState);
  const [categoryLimit, setCategoryLimit] = useState<any>(4);
  const [postsOrdering, setPostsOrdering] = useState<any>("-updated_date");
  const [search, setSearch] = useState<any>("");
  const [isDisplayLoader, setIsDisplayLoader] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<any>("");
  const [nextUrl, setNextUrl] = useState<any>("");
  const [currentOffset, setCurrentOffSet] = useState<any>("");
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [categoryName, setCategoryName] = useState<any>("");
  const [validSelect, setValidSelect] = useState<boolean>(false);
  const [categoryOffset, setCategoryOffSet] = useState<any>("");
  const [imageLoad, setImageLoad] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState<boolean>(true);
  const [isStoryLoading, setIsStoryLoading] = useState<boolean>(true);

  const debouncedSearchTerm = useDebounce(search, 500);

  const toggle = (toolTipId: string) => {
    setTooltip(toolTipId);
    setTooltipOpen(!tooltipOpen);
  };

  useEffect(() => {
    goToTop(setIsDisplayLoader);
    blogsStoriesList().then();
  }, []);

  useEffect(() => {
    blogsCategoryList().then();
  }, [categoryLimit, currentOffset]);

  useEffect(() => {
    postsList().then();
  }, [pageState.currentOffset, categoryId, postsOrdering, debouncedSearchTerm]);

  const scrollOnClick = () => {
    const element = document.getElementById("section-1");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  };

  const fetchAsyncCategory = async () => {
    let options: any = [];

    const response = await getRequest(
      BLOG_CATEGORY_LIST + `?limit=${categoryLimit}&offset=${categoryOffset}`,
      navigate,
      false
    );

    let hasMore: boolean = false;

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next);
      let newOffset: any = queryParams.get("offset");
      setCategoryOffSet(newOffset);
      hasMore = true;
    }

    if (categoryList.length === 0) {
      options.push({
        label: "All Categories", // these
        value: "",
      });
    }

    if (response.results.length > 0) {
      response.results.map((option: any) => {
        options.push({
          label: option.title,
          value: option.id,
        });
      });
    }

    if (categoryList.length > 0) {
      setCategoryList(categoryList.concat(options));
    } else {
      setCategoryList(options);
    }

    return {
      options: options,
      hasMore: hasMore,
    };
  };

  const blogsCategoryList = async () => {
    setIsCategoriesLoading(true);
    setIsDisplayLoader(true);
    const response = await getRequest(
      BLOG_CATEGORY_LIST + `?limit=${categoryLimit}&offset=${currentOffset}`,
      navigate,
      false
    );

    setNextUrl(response.next);
    setIsDisplayLoader(false);

    setLoadMore(false);

    if (response.next && !["", null, "null"].includes(response.next)) {
      setLoadMore(true);
    }

    if (categoryList.length === 0) {
      setCategoryList(response.results);
    } else {
      setCategoryList(categoryList.concat(response.results));
    }
    setIsCategoriesLoading(false);
  };

  const blogsStoriesList = async () => {
    setIsStoryLoading(true);
    setIsDisplayLoader(true);
    const response = await getRequest(
      BLOG_STORIES_LIST + `?limit=3&ordering=-updated_at`,
      navigate,
      false
    );

    setShowMore(false);

    if (response.next && !["", null, "null"].includes(response.next)) {
      setShowMore(true);
    }

    setStoryList(response.results);
    setIsDisplayLoader(false);
    setIsStoryLoading(false);
  };

  const postsList = async () => {
    setIsLoading(true);
    let blogListApi =
      BLOG_POST_LIST +
      `?limit=${pageState.limit}&offset=${pageState.currentOffset}&ordering=${postsOrdering}`;

    if (debouncedSearchTerm !== "") {
      blogListApi += `&search=${debouncedSearchTerm}`;
    }

    if (categoryId !== "") {
      blogListApi += `&category=${categoryId}`;
    }

    setIsDisplayLoader(true);
    const response = await getRequest(blogListApi, navigate, false);
    let postData: any = [];
    if (response.results.length > 0) {
      setPostsList([]);
      setPageState(initialPageState);

      response.results.map((post: any, index: number) => {
        postData.push(post);
        postData[index].chip_category = [];

        if (post.category.length > 0) {
          postData[index].chip_category.push({
            title: post.category[0].title,
            color: post.category[0].colour_code,
          });
          if (post.category.length == 2) {
            postData[index].chip_category.push({
              title: post.category[1].title,
              color: post.category[1].colour_code,
            });
          }
        }
        postData[index].more_category_count = 0;

        if (post.category.length > 2) {
          postData[index].more_category_count = post.category.length - 1;
        }

        let tooltipCategories: string = "";

        if (post.category.length > 0) {
          post.category.map((category: any, categoryIndex: number) => {
            if (categoryIndex > 0) {
              tooltipCategories += `${category.title}`;

              if (categoryIndex !== post.category.length - 1) {
                tooltipCategories += " & ";
              }
            }
          });
        }

        postData[index].tooltip_categories = tooltipCategories;
      });
    }

    setIsDisplayLoader(false);
    setIsLoading(false);
    setPostsList(postData);
    setPageState({
      ...pageState,
      list: response.results,
      next: response.next,
      count: response.count,
      previous: response.previous,
    });
  };

  const handleClick = (item: any) => {
    setCategoryId(item.id);
    setCategoryName(item.title);
    setValidSelect(true);
    setIsOpen(!isOpen);
  };

  const handleChangeSearch = (e: any) => {
    setSearch(e.target.value);
  };

  const onChangeSelection = (e: any) => {
    setPostsList([]);
    // setCurrentOffSet("");
    setPageState(initialPageState);

    if (e.target.value == "Recent First") {
      setPostsOrdering("-published_date");
    }
    if (e.target.value == "Created First") {
      setPostsOrdering("id");
    }
  };

  const clearButtonOnClick = () => {
    setCategoryId("");
    setValidSelect(false);
    setCategoryName("All Categories");
  };

  return (
    <>
      <CustomHelmet
        title={`Our Blog | EA Foods`}
        metaDescription={`Welcome to the EA Foods blog. We provide a wide range of the highest quality fresh and exotic produce directly from farmers to the customers.`}
        imagePath={metaImage}
      />
      <HeroBanner
        heroTitle="Our Blog"
        heroDesc="Our blog features insights from industry leaders, including our own team members. Read their thoughts on the latest trends, challenges, and opportunities in the Agribusiness industry and be sure to check back for new blog posts and updates."
        isButton={false}
        path="/media/images/heroblog.jpg"
        heroImageMobile="/media/images/heroBlogM.jpg"
      />
      {/* {isDisplayLoader && <CustomLoader />} */}
      <section className="section-padding">
        <Container>
          <Row className="flex-md-row-reverse position-relative">
            <Col lg>
              <div className="bloglisting">
                <Row className="g-0 justify-content-between">
                  <Col md="6" lg="5">
                    {/* <Input
                      className="form-search mb-10px"
                      placeholder="Search"
                      onChange={handleChangeSearch}
                    /> */}
                    {/* close */}
                    <InputGroup className="mb-10px">
                      <Input
                        className="form-search"
                        placeholder="Search"
                        value={search}
                        onChange={handleChangeSearch}
                      />
                      {search.length > 0 && (
                        <InputGroupText
                          className="bg-white border-start-0 cursor-pointer"
                          onClick={() => setSearch("")}
                        >
                          <KTSVG
                            path="/media/icons/ic_close.svg"
                            className="ic"
                          />
                        </InputGroupText>
                      )}
                    </InputGroup>
                  </Col>
                  <Col md="auto">
                    <div className="d-flex sort-group align-items-center">
                      <span className="w-56px text-muted fw-600" id="section-1">
                        Sort by
                      </span>
                      <Input
                        type={"select"}
                        id="exampleSelect"
                        name="select"
                        onChange={onChangeSelection}
                      >
                        <option value={"Recent First"}>Recent First</option>
                        <option value={"Created First"}>Created First</option>
                      </Input>
                    </div>
                  </Col>
                </Row>
                {isLoading ? (
                  <>
                    <Row>
                      <Col md="6" className="mt-30px">
                        <div
                          className="position-relative"
                          style={{ height: "320px" }}
                        >
                          <span className="skeleton-box"></span>
                        </div>
                      </Col>
                      <Col md="6" className="mt-30px">
                        <div
                          className="position-relative"
                          style={{ height: "320px" }}
                        >
                          <span className="skeleton-box"></span>
                        </div>
                      </Col>
                      <Col md="6" className="mt-30px">
                        <div
                          className="position-relative"
                          style={{ height: "320px" }}
                        >
                          <span className="skeleton-box"></span>
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    {postList.length == 0 ? (
                      <>
                        <div className="mt-4">
                          <NoRecords />
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        <Row>
                          {postList.map((post: any) => {
                            console.log("chip ", post);

                            return (
                              <>
                                <Col md="6" className="mt-30px">
                                  <a
                                    href="JAVASCRIPT:void(0)"
                                    className="text-dark"
                                    onClick={() => {
                                      navigate(`/blog/${post.slug}`);
                                    }}
                                  >
                                    <Card
                                      className={`border-0 position-relative h-100`}
                                    >
                                      {post.cover_image == null ? (
                                        <img
                                          alt="group image"
                                          src={toAbsoluteUrl(
                                            "/media/images/img_people1.jpg"
                                          )}
                                        />
                                      ) : (
                                        <div className="position-relative flex-grow-1">
                                          <img
                                            src={post.cover_image}
                                            className="rounded-top"
                                            alt=""
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              minHeight: "240px",
                                              maxHeight: "240px",
                                              objectFit: "cover",
                                            }}
                                            onLoad={() => {
                                              setImageLoad(true);
                                            }}
                                          />
                                          {!imageLoad && (
                                            <span className="skeleton-box"></span>
                                          )}
                                        </div>
                                      )}
                                      <CardBody className="d-flex flex-column">
                                        <CardTitle
                                          tag="h5"
                                          className="truncate-2 mb-10 blog-title"
                                        >
                                          {post.title}
                                        </CardTitle>
                                        <CardText className="truncate-3 mb-2">
                                          {post.short_desc}
                                        </CardText>
                                        <div className="d-flex justify-content-between mt-auto">
                                          <div className="d-flex flex-wrap justify-content-between">
                                            {post.chip_category &&
                                              post.chip_category.length > 0 && (
                                                <>
                                                  {post.chip_category.map(
                                                    (chip: any) => {
                                                      return (
                                                        <Chip
                                                          className="me-2 mt-1"
                                                          chipText={chip.title}
                                                          customStyle={{
                                                            color: chip.color
                                                              ? chip.color
                                                              : "#0c78a4",
                                                            borderColor:
                                                              chip.color
                                                                ? chip.color
                                                                : "#0c78a4",
                                                            marginRight: "10px",
                                                          }}
                                                        ></Chip>
                                                      );
                                                    }
                                                  )}
                                                  {post.more_category_count >
                                                    0 && (
                                                    <div className="mt-1 me-2">
                                                      <span
                                                        id={
                                                          "tooltip_" + post.id
                                                        }
                                                      >
                                                        {
                                                          post.more_category_count
                                                        }
                                                        + more
                                                      </span>
                                                      <Tooltip
                                                        placement="top"
                                                        isOpen={
                                                          tooltip ===
                                                            "tooltip_" +
                                                              post.id &&
                                                          tooltipOpen
                                                        }
                                                        autohide={false}
                                                        target={
                                                          "tooltip_" + post.id
                                                        }
                                                        toggle={() =>
                                                          toggle(
                                                            "tooltip_" + post.id
                                                          )
                                                        }
                                                      >
                                                        {
                                                          post.tooltip_categories
                                                        }
                                                      </Tooltip>
                                                    </div>
                                                  )}
                                                </>
                                              )}
                                          </div>
                                          <span className="text-muted blogdate mt-1">
                                            {
                                              dateFormat(post.published_date)
                                                .displayDate
                                            }
                                          </span>
                                        </div>
                                      </CardBody>
                                    </Card>
                                  </a>
                                </Col>
                              </>
                            );
                          })}
                        </Row>
                        <CustomPagination
                          pageState={pageState}
                          setPageState={setPageState}
                          scrollOnClick={scrollOnClick}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </Col>
            <Col lg="auto">
              <div className="categoryBar">
                <div className="category-inner">
                  <div className="d-flex justify-content-between align-items-center mb-2 mb-md-0">
                    <h5 className="mb-10px font-20">Categories</h5>
                    {categoryId ? (
                      <>
                        <Button
                          className="btn-rounded py-1 pe-0 text-secondary text-decoration-none text-hover-primary align-self-start"
                          color="link"
                          size="sm"
                          onClick={clearButtonOnClick}
                        >
                          <KTSVG
                            path="/media/icons/ic_close.svg"
                            className="ic me-1 ic-18 text-hover-primary"
                          />
                          Clear All
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <img
                    src={toAbsoluteUrl("/media/images/title_border.svg")}
                    alt=""
                    className="mb-3 mt-0 d-none d-lg-block"
                  />
                  <div className="d-block d-lg-none">
                    <AsyncPaginate
                      loadOptions={fetchAsyncCategory}
                      id="product_categories"
                      isSearchable={false}
                      className={clsx("react-select-container w-100")}
                      classNamePrefix="react-select"
                      onChange={(e: any) => {
                        setCategoryId(e.value);
                      }}
                      value={
                        categoryId &&
                        categoryList.find(
                          (option: any) => option.value === categoryId
                        )
                      }
                      theme={(theme: any) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#0c78a4",
                        },
                      })}
                    />
                  </div>
                  <div className="d-none d-lg-block">
                    <Collapse isOpen={isOpen} className="category-collapse">
                      <ListGroup flush>
                        {isCategoriesLoading ? (
                          <>
                            {/* category list loader start */}
                            <div className="mb-10px">
                              <div
                                className="position-relative"
                                style={{ height: "34px" }}
                              >
                                <span className="skeleton-box"></span>
                              </div>
                            </div>
                            <div className="mb-10px">
                              <div
                                className="position-relative"
                                style={{ height: "34px" }}
                              >
                                <span className="skeleton-box"></span>
                              </div>
                            </div>
                            <div className="mb-10px">
                              <div
                                className="position-relative"
                                style={{ height: "34px" }}
                              >
                                <span className="skeleton-box"></span>
                              </div>
                            </div>
                            {/* category list loader end */}
                          </>
                        ) : (
                          <>
                            {categoryList.map((item: any) => {
                              return (
                                <>
                                  <ListGroupItem
                                    className={clsx(
                                      "px-3 px-md-0 cursor-pointer",
                                      selectedId == item.id &&
                                        validSelect == true
                                        ? "selected"
                                        : ""
                                    )}
                                    tag="a"
                                    onClick={() => {
                                      handleClick(item);
                                      setSelectedId(item.id);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {item.title}
                                  </ListGroupItem>
                                </>
                              );
                            })}
                          </>
                        )}
                      </ListGroup>

                      {!isCategoriesLoading && loadMore && (
                        <ListGroupItem
                          tag="a"
                          onClick={() => setCurrentOffSet(nextOffset(nextUrl))}
                          style={{ cursor: "pointer" }}
                          className="d-flex justify-content-between px-3 px-md-0 view-more"
                        >
                          <>
                            <span>View More </span>
                            <KTSVG
                              path="/media/icons/ic_chevron_right.svg"
                              className="ic"
                            />
                          </>
                        </ListGroupItem>
                      )}
                    </Collapse>
                  </div>
                </div>
                <h5 className="mb-10px mt-4 pt-3">Success Stories</h5>
                <img
                  src={toAbsoluteUrl("/media/images/title_border.svg")}
                  alt=""
                  className="mb-3 mt-0"
                />
                <ul className="list-unstyled mb-0">
                  {isStoryLoading ? (
                    <>
                      {/* category list loader start */}
                      <div className="mb-10px">
                        <div
                          className="position-relative"
                          style={{ height: "70px" }}
                        >
                          <span className="skeleton-box"></span>
                        </div>
                      </div>
                      <div className="mb-10px">
                        <div
                          className="position-relative"
                          style={{ height: "70px" }}
                        >
                          <span className="skeleton-box"></span>
                        </div>
                      </div>
                      <div className="mb-10px">
                        <div
                          className="position-relative"
                          style={{ height: "70px" }}
                        >
                          <span className="skeleton-box"></span>
                        </div>
                      </div>
                      {/* category list loader end */}
                    </>
                  ) : (
                    <>
                      {storyList.map((story: any) => {
                        return (
                          <>
                            <li className="mb-20px">
                              <a
                                href="JAVASCRIPT:void(0)"
                                style={{ cursor: "pointer" }}
                                className="text-dark"
                                onClick={() => {
                                  navigate(`/success-stories/${story.slug}`);
                                }}
                              >
                                <Card className="bg-transparent border-0 shadow-none">
                                  <Row className="g-0">
                                    <Col xs="4">
                                      <div className="position-relative flex-grow-1">
                                        <img
                                          src={story.profile_picture}
                                          alt=""
                                          className="story-avtar blog-story-avtar"
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                          }}
                                          onLoad={() => {
                                            setImageLoad(true);
                                          }}
                                        />
                                        {!imageLoad && (
                                          <span className="skeleton-box"></span>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs="8">
                                      <CardBody className="p-0 ms-3">
                                        <CardTitle
                                          tag="h5"
                                          className="truncate-2 mb-10 font-16 fw-700"
                                        >
                                          {story.firstname} {story.lastname}
                                        </CardTitle>
                                        <CardText className="truncate-3 mb-10">
                                          {story.location_text}
                                        </CardText>
                                        <div className="d-flex justify-content-between">
                                          {story.usertype === "Employee" ? (
                                            <Chip
                                              className="primary"
                                              chipText="Employees Stories"
                                            ></Chip>
                                          ) : story.usertype === "Farmer" ? (
                                            <Chip
                                              className="success"
                                              chipText="Farmers Stories"
                                            ></Chip>
                                          ) : (
                                            story.usertype === "Customer" && (
                                              <Chip
                                                className="warning"
                                                chipText="Customers Stories"
                                              ></Chip>
                                            )
                                          )}
                                        </div>
                                      </CardBody>
                                    </Col>
                                  </Row>
                                </Card>
                              </a>
                            </li>
                          </>
                        );
                      })}
                    </>
                  )}
                </ul>
                {!isStoryLoading && showMore && (
                  <a
                    href="javascript:void(0)"
                    className="text-muted fw-600 pt-2 d-inline-block cursor-pointer text-hover-primary"
                    onClick={() => navigate("/success-stories")}
                  >
                    Show More
                  </a>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MasterLayout(Blogs);
