import React from "react"
import { toAbsoluteUrl } from "../../helper/AssetHelpers"

const CustomLoader = () => {
    return <>
        <div id='loader'
            style={{ position: "absolute", zIndex: "3333333" }}
        >
            <div className='preloader d-flex align-items-center justify-content-center'>
                <img
                    src={toAbsoluteUrl("/media/icons/loader.svg")}
                    alt="Loading..." />
            </div>
        </div>
    </>
}

export default CustomLoader
