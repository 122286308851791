import React from "react"

type Props = {
  chipText?: any;
  className?: string;
  customStyle?: any;
};
const Chip: React.FC<Props> = ({ chipText, className, customStyle }) => {
  console.log("customStyle ======= ", customStyle);
  return (
    <>
      <span className={`badge badge-outline-${className}`} style={customStyle}>
        {chipText}
      </span>
    </>
  );
};

export default Chip;
