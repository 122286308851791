import axios from "axios"
import {requestHeaders} from "../helpers/CommonHelpers";

export const postRequest = async (URL: any, formData: any, navigate: any, token?: any) => {
  let error: any = null;
  const response: any = await axios
    .post(URL, formData, requestHeaders())
    .catch((e) => {
      error = e.response;
      navigate('/not-respond')
    });

  if (error !== null) {
    return error;
  }

  return response;
};

export const getRequest = async (URL: any, navigate: any, token?: any) => {
  let error: any = null;
  const response: any = await axios.get(URL, requestHeaders()).catch((e) => {
    error = e.response;
    navigate('/not-respond')
  });

  if (error !== null) {
    return error;
  }

  return response.data;
};

export const putRequest = async (URL: any, formData: any, token?: any) => {
  let error: any = null;
  const response: any = await axios
    .put(URL, formData, requestHeaders())
    .catch((e) => {
      error = e.response;
    });

  if (error !== null) {
    return error;
  }
  return response;
};

export const patchRequest = async (URL: any, formData: any, token?: any) => {
  let error: any = null;
  const response: any = await axios
    .patch(URL, formData, requestHeaders())
    .catch((e) => {
      error = e.response;
    });

  if (error !== null) {
    return error;
  }
  return response;
};

export const deleteRequest = async (URL: any, token?: any) => {
  const getToken = localStorage.getItem("token");

  const header = {
    headers: {
      Authorization: `Bearer ${getToken}`,
    },
  };

  let error: any = null;
  const response: any = await axios.delete(URL, requestHeaders()).catch((e) => {
    error = e.response;
  });

  if (error !== null) {
    return error;
  }
  return response;
};
