import React from 'react'
import { toAbsoluteUrl } from '../../helper/AssetHelpers'
import { Col } from 'reactstrap'
type Props ={
    src:any,
    alt:string,
    title:string,
} 
const InvestorLogo: React.FC<Props> = ({src, alt, title})  => {
    return (
        <>
    <Col md="6" lg="3" className="mt-30px">
    <div className="border inv-card h-100 rounded-4 d-flex align-items-center justify-content-center">
      <img
        src={toAbsoluteUrl(`/media/images/${src}.png`)}
        srcSet={toAbsoluteUrl(`/media/images/${src}@2x.png 2x`)}
        alt={alt}
        title={title}
        className="img-fluid"
      />
    </div>
  </Col>
  </>
    )
}
export default InvestorLogo