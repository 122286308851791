import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  Button,
  Col,
  Collapse,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import {
  goToTop,
  nextOffset,
} from "../../../_eaFruitsCms/helpers/CommonHelpers";
import { getRequest } from "../../../_eaFruitsCms/https/apiCall";
import {
  BRAND_LIST,
  CATEGORIES_LIST,
  PRODUCT_GROUP_LIST,
} from "../../../_eaFruitsCms/https/ApiRoutes";
import { metaImage, toAbsoluteUrl } from "../../../helper/AssetHelpers";
import { KTSVG } from "../../../helper/components/KTSVG";
import HeroBanner from "../../../layout/components/banners/heroBanner";
import ProductCard from "../../../layout/components/cards/productCard";
import CustomPagination from "../../../layout/components/CustomPagination";
import SectionHeading from "../../../layout/components/headings/sectionHeading";
import NoRecords from "../../../layout/components/noRecords/noRecords";
import MasterLayout from "../../../layout/masterlayout";
import CustomHelmet from "../../components/CustomHelmet";
import CustomLoader from "../../components/CustomLoader";
import IsLoading from "../../components/Loader";

const Products = (props: any) => {
  const navigate = useNavigate();
  const scrollToRef: any = useRef();
  const errorImage = toAbsoluteUrl("/media/logos/logo_eafruits.png");

  const initialPageState = {
    search: "",
    limit: 12,
    offset: "",
    list: [],
    next: "",
    count: "",
    previous: "",
    isLoading: true,
    currentOffset: "",
    selected: 0,
  };

  const [isOpen, setIsOpen] = useState(false);
  const [category, setCategory] = useState<any>([
    {
      name: "All Categories",
      id: "",
    },
  ]);
  const [products, setProducts] = useState<any>([]);
  const [brands, setBrands] = useState<any>([]);
  const [listLoader, setListLoader] = useState<any>(true);
  const [categoryLimit, setCategoryLimit] = useState<any>(4);
  const [categorySlug, setCategorySlug] = useState<any>("");
  const [brandSlug, setBrandSlug] = useState<any>("");
  const [pageState, setPageState] = useState(initialPageState);
  const [selected, setSelected] = useState<any>("");
  const [selectedCategory, setSelectedCategory] = useState<any>("");
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [nextUrl, setNextUrl] = useState<any>("");
  const [currentOffset, setCurrentOffSet] = useState<any>("");
  const [categoryOffset, setCategoryOffSet] = useState<any>("");
  const [categoryName, setCategoryName] = useState<any>("");
  const [validSelectCategory, setValidSelectCategory] =
    useState<boolean>(false);
  const [validSelectbrand, setValidSelectBrand] = useState<boolean>(false);
  const [isBrandsLoading, setIsBrandsLoading] = useState<boolean>(true);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState<boolean>(true);
  const [isProductsLoading, setIsProductsLoading] = useState<boolean>(true);

  const toggleCategory = () => setIsOpen(!isOpen);

  useEffect(() => {
    goToTop(setListLoader);
    fetchBrands().then();
  }, []);

  useEffect(() => {
    fetchCategory().then();
  }, [currentOffset]);

  useEffect(() => {
    fetchProducts().then();
  }, [categorySlug, brandSlug, pageState.currentOffset]);

  const fetchBrands = async () => {
    setIsBrandsLoading(true);
    setListLoader(true);
    const response = await getRequest(
      BRAND_LIST + `?limit=10`,
      navigate,
      false
    );
    setBrands(response.results);
    setListLoader(false);
    setIsBrandsLoading(false);
  };

  const fetchCategory = async () => {
    setListLoader(true);
    setIsCategoriesLoading(true);

    const response = await getRequest(
      CATEGORIES_LIST + `?limit=${categoryLimit}&offset=${currentOffset}`,
      navigate,
      false
    );
    setNextUrl(response.next);

    setLoadMore(false);

    if (response.next && !["", null, "null"].includes(response.next)) {
      setLoadMore(true);
    }

    if (category.length === 0) {
      setCategory(response.results);
    } else {
      setCategory(category.concat(response.results));
    }

    setListLoader(false);
    setIsCategoriesLoading(false);
  };

  const fetchProducts = async () => {
    setListLoader(true);
    setIsProductsLoading(true);

    let productAPI =
      PRODUCT_GROUP_LIST +
      `?limit=${pageState.limit}&offset=${pageState.currentOffset}&ordering=sorting_order`;

    if (brandSlug !== "") {
      productAPI += `&brand_slug=${brandSlug}`;
    }

    if (categorySlug && categorySlug !== "") {
      productAPI += `&category_slug=${categorySlug}`;
    }
    const response = await getRequest(productAPI, navigate, false);
    setProducts(response.results);
    setPageState({
      ...pageState,
      list: response.results,
      next: response.next,
      count: response.count,
      previous: response.previous,
    });
    setListLoader(false);
    setIsProductsLoading(false);
  };

  const handleClick = (e: any, name: any) => {
    if (name == "product") {
      setCategorySlug(e.slug);
      setCategoryName(e.name);
      setIsOpen(!isOpen);
      setValidSelectCategory(true);
      scrollOnClick();
    }
    if (name == "brand") {
      setBrandSlug(e.slug);
      setValidSelectBrand(true);
      scrollOnClick();
    }
  };

  const scrollOnClick = () => {
    const element = document.getElementById("section-1");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  };

  const clearButtonOnClick = () => {
    setCategorySlug("");
    setValidSelectCategory(false);
    setValidSelectBrand(false);
    setBrandSlug("");
    setCategoryName("All Categories");
  };

  const fetchAsyncCategory = async () => {
    let options: any = [];

    let categoryListAPI =
      CATEGORIES_LIST + `?limit=${categoryLimit}&offset=${categoryOffset}`;

    const response = await getRequest(categoryListAPI, navigate, false);

    let hasMore: boolean = false;

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next);
      let newOffset: any = queryParams.get("offset");
      setCategoryOffSet(newOffset);
      hasMore = true;
    }

    if (category.length === 0) {
      options.push({
        label: "All Categories", // these
        value: "",
      });
    }

    if (response.results.length > 0) {
      response.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.slug,
        });
      });
    }

    if (category.length > 0) {
      setCategory(category.concat(options));
    } else {
      setCategory(options);
    }

    return {
      options: options,
      hasMore: hasMore,
    };
  };

  return (
    <>
      {/* {listLoader && <CustomLoader />} */}
      <CustomHelmet
        title={`EA Foods Products - Fair Price & Quality Product`}
        metaDescription={`We provide the best products with High quality that you always look for. Check out our products list of fresh fruits, vegetables and grains here.`}
        imagePath={metaImage}
      />

      <HeroBanner
        heroTitle="Our Products"
        heroDesc="We provide you with a wide range of top-quality products that are both diverse and convenient to cater for your variety of needs and tastes."
        path="/media/images/heroproduct.jpg"
        heroImageMobile="/media/images/heroProductM.jpg"
        isButton2={true}
        buttonText2="Explore Our Products"
        onClick2={() => {
          scrollToRef.current.scrollIntoView();
        }}
      />
      <section ref={scrollToRef} className="section-padding">
        <Container fluid="xl">
          <SectionHeading
            sectionTitle="Our Products"
            sectionPara="We provide the best products with High quality, check some of our products below."
          />
          <Row className="position-relative">
            <Col lg="auto">
              <div className="categoryBar pt-0">
                <div>
                  <div className=" border-bottom mb-30px mt-30px d-flex justify-content-between pb-10px align-items-center">
                    <h5 className="mb-0">Filter By</h5>
                    {categorySlug || brandSlug ? (
                      <>
                        <Button
                          className="btn-rounded py-2 pe-0 text-secondary text-decoration-none text-hover-primary"
                          color="link"
                          size="sm"
                          onClick={clearButtonOnClick}
                        >
                          <KTSVG
                            path="/media/icons/ic_close.svg"
                            className="ic me-1 ic-18 text-hover-primary"
                          />
                          Clear All
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <h5 className="mb-10px" id="section-1">
                    Brands
                  </h5>
                  <img
                    src={toAbsoluteUrl("/media/images/title_border.svg")}
                    alt=""
                    className="mb-3 mt-0"
                  />
                  <Row>
                    {isBrandsLoading ? (
                      <>
                        {/* brand filter loader start */}
                        <Row>
                          <Col
                            xs={"auto"}
                            sm={3}
                            lg={4}
                            md={"auto"}
                            className="mb-30px"
                          >
                            <div
                              className="position-relative"
                              style={{ height: "80px" }}
                            >
                              <span className="skeleton-box"></span>
                            </div>
                          </Col>
                          <Col
                            xs={"auto"}
                            sm={3}
                            lg={4}
                            md={"auto"}
                            className="mb-30px"
                          >
                            <div
                              className="position-relative"
                              style={{ height: "80px" }}
                            >
                              <span className="skeleton-box"></span>
                            </div>
                          </Col>
                          <Col
                            xs={"auto"}
                            sm={3}
                            lg={4}
                            md={"auto"}
                            className="mb-30px"
                          >
                            <div
                              className="position-relative"
                              style={{ height: "80px" }}
                            >
                              <span className="skeleton-box"></span>
                            </div>
                          </Col>
                          <Col
                            xs={"auto"}
                            sm={3}
                            lg={4}
                            md={"auto"}
                            className="mb-30px"
                          >
                            <div
                              className="position-relative"
                              style={{ height: "80px" }}
                            >
                              <span className="skeleton-box"></span>
                            </div>
                          </Col>
                          <Col
                            xs={"auto"}
                            sm={3}
                            lg={4}
                            md={"auto"}
                            className="mb-30px"
                          >
                            <div
                              className="position-relative"
                              style={{ height: "80px" }}
                            >
                              <span className="skeleton-box"></span>
                            </div>
                          </Col>
                          <Col
                            xs={"auto"}
                            sm={3}
                            lg={4}
                            md={"auto"}
                            className="mb-30px"
                          >
                            <div
                              className="position-relative"
                              style={{ height: "80px" }}
                            >
                              <span className="skeleton-box"></span>
                            </div>
                          </Col>
                        </Row>
                        {/* brand filter loader end */}
                      </>
                    ) : (
                      <>
                        {brands.map((item: any) => {
                          return (
                            <>
                              <Col
                                xs={"auto"}
                                sm={3}
                                lg={4}
                                md={"auto"}
                                className="mb-30px"
                              >
                                {/* <div className="brand-card border h-100 rounded text-center d-flex align-items-center justify-content-center cursor-pointer"> */}
                                <div
                                  className={clsx(
                                    "brand-card h-100 rounded text-center d-flex align-items-center justify-content-center cursor-pointer",
                                    selected == item.slug &&
                                      validSelectbrand == true
                                      ? "selected"
                                      : ""
                                  )}
                                >
                                  <img
                                    src={item.brand_thumbnail}
                                    alt="potato king"
                                    onClick={() => {
                                      handleClick(item, "brand");
                                      setSelected(item.slug);
                                    }}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = `${errorImage}`;
                                    }}
                                  />
                                </div>
                              </Col>
                            </>
                          );
                        })}
                      </>
                    )}
                  </Row>
                </div>
                <div className="category-inner position-static">
                  <div>
                    <h5 className="mb-10px">Categories</h5>
                    <img
                      src={toAbsoluteUrl("/media/images/title_border.svg")}
                      alt=""
                      className="mb-3 mt-0 d-none d-lg-block"
                    />
                  </div>
                  <div className="d-block d-lg-none">
                    <AsyncPaginate
                      loadOptions={fetchAsyncCategory}
                      id="product_categories"
                      isSearchable={false}
                      className={clsx("react-select-container w-100")}
                      classNamePrefix="react-select"
                      onChange={(e: any) => {
                        setCategorySlug(e.value);
                      }}
                      value={
                        categorySlug &&
                        category.find(
                          (option: any) => option.value === categorySlug
                        )
                      }
                      theme={(theme: any) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#0c78a4",
                        },
                      })}
                    />
                  </div>
                  <div className="d-none d-md-block">
                    <Collapse isOpen={isOpen} className="category-collapse">
                      <ListGroup flush>
                        {isCategoriesLoading ? (
                          <>
                            {/* category list loader start */}
                            <div className="mb-10px">
                              <div
                                className="position-relative"
                                style={{ height: "34px" }}
                              >
                                <span className="skeleton-box"></span>
                              </div>
                            </div>
                            <div className="mb-10px">
                              <div
                                className="position-relative"
                                style={{ height: "34px" }}
                              >
                                <span className="skeleton-box"></span>
                              </div>
                            </div>
                            <div className="mb-10px">
                              <div
                                className="position-relative"
                                style={{ height: "34px" }}
                              >
                                <span className="skeleton-box"></span>
                              </div>
                            </div>
                            {/* category list loader end */}
                          </>
                        ) : (
                          <>
                            {category.map((item: any) => {
                              return (
                                <>
                                  <ListGroupItem
                                    className={clsx(
                                      "px-3 px-md-0 cursor-pointer",
                                      selectedCategory == item.slug &&
                                        validSelectCategory == true
                                        ? "selected"
                                        : ""
                                    )}
                                    tag="a"
                                    onClick={() => {
                                      handleClick(item, "product");
                                      setSelectedCategory(item.slug);
                                    }}
                                  >
                                    {item.name}
                                  </ListGroupItem>
                                </>
                              );
                            })}
                          </>
                        )}
                      </ListGroup>
                      {!isCategoriesLoading && loadMore && (
                        <ListGroupItem
                          tag="a"
                          onClick={() => setCurrentOffSet(nextOffset(nextUrl))}
                          className="cursor-pointer d-flex justify-content-between px-3 px-md-0 border-start-0 border-end-0 border-bottom-0"
                        >
                          {loadMore && (
                            <>
                              <span>Others</span>
                              <KTSVG
                                path="/media/icons/ic_chevron_right.svg"
                                className="ic"
                              />
                            </>
                          )}
                        </ListGroupItem>
                      )}
                    </Collapse>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg>
              <div className="productlisting">
                {isProductsLoading ? (
                  // <IsLoading />
                  <>
                    {/* product loader start */}
                    <Row>
                      <Col md="4" lg="4" className="mt-30px">
                        <div
                          className="position-relative"
                          style={{ height: "400px" }}
                        >
                          <span className="skeleton-box"></span>
                        </div>
                      </Col>
                      <Col md="4" lg="4" className="mt-30px">
                        <div
                          className="position-relative"
                          style={{ height: "400px" }}
                        >
                          <span className="skeleton-box"></span>
                        </div>
                      </Col>
                      <Col md="4" lg="4" className="mt-30px">
                        <div
                          className="position-relative"
                          style={{ height: "400px" }}
                        >
                          <span className="skeleton-box"></span>
                        </div>
                      </Col>
                      <Col md="4" lg="4" className="mt-30px">
                        <div
                          className="position-relative"
                          style={{ height: "400px" }}
                        >
                          <span className="skeleton-box"></span>
                        </div>
                      </Col>
                      <Col md="4" lg="4" className="mt-30px">
                        <div
                          className="position-relative"
                          style={{ height: "400px" }}
                        >
                          <span className="skeleton-box"></span>
                        </div>
                      </Col>
                      <Col md="4" lg="4" className="mt-30px">
                        <div
                          className="position-relative"
                          style={{ height: "400px" }}
                        >
                          <span className="skeleton-box"></span>
                        </div>
                      </Col>
                      <Col md="4" lg="4" className="mt-30px">
                        <div
                          className="position-relative"
                          style={{ height: "400px" }}
                        >
                          <span className="skeleton-box"></span>
                        </div>
                      </Col>
                      <Col md="4" lg="4" className="mt-30px">
                        <div
                          className="position-relative"
                          style={{ height: "400px" }}
                        >
                          <span className="skeleton-box"></span>
                        </div>
                      </Col>
                      <Col md="4" lg="4" className="mt-30px">
                        <div
                          className="position-relative"
                          style={{ height: "400px" }}
                        >
                          <span className="skeleton-box"></span>
                        </div>
                      </Col>
                    </Row>
                    {/* product loader end */}
                  </>
                ) : (
                  <>
                    {products.length == 0 ? (
                      <>
                        <div className="mt-4">
                          <NoRecords />
                        </div>
                      </>
                    ) : (
                      <>
                        <Row>
                          {products.map((item: any) => {
                            return (
                              <>
                                <Col md="4" lg="4" className="mt-30px">
                                  <a
                                    className="text-dark cursor-pointer"
                                    onClick={() => {
                                      localStorage.setItem(
                                        "productId",
                                        item.id
                                      );
                                      navigate(`/product/${item.slug}`);
                                    }}
                                  >
                                    <ProductCard
                                      title={item.name}
                                      description={item.short_description}
                                      path={item.group_image}
                                    />
                                  </a>
                                </Col>
                              </>
                            );
                          })}
                        </Row>
                        <CustomPagination
                          pageState={pageState}
                          setPageState={setPageState}
                          scrollOnClick={scrollOnClick}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MasterLayout(Products);
