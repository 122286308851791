import React from "react"
import { useNavigate } from "react-router-dom"
import { Button, Col, Container, Row } from "reactstrap"
import { toAbsoluteUrl } from "../../../helper/AssetHelpers"

type Props = {
  heroTitle?: any;
  heroDesc?: string;
  buttonText?: string;
  buttonText2?: string;
  isButton?: boolean;
  isButton2?: boolean;
  className?: string;
  path?: any;
  onClick1?: any;
  onClick2?: any;
  href?: any;
  heroImageMobile?: any;
};
const HeroBanner: React.FC<Props> = ({
  heroTitle,
  heroDesc,
  buttonText,
  buttonText2,
  className,
  path,
  isButton,
  isButton2,
  onClick1,
  onClick2,
  href,
  heroImageMobile
}) => {
  const navigate = useNavigate();

  return (
    <>
      {/* <section className="hero-banner about-hero-banner">
        <Container className="h-100" fluid="xl">
          <div className="position-relative h-100">
            <Row className="align-items-center h-100">
              <Col lg={5}>
                <div className="py-4">
                  <h1 className="herotitle">{heroTitle}</h1>
                  <p>{heroDesc}</p>
                  {isButton2 ? (
                    <a
                      color="primary"
                      className="btn btn-primary btn-rounded me-3 mb-3 mb-md-0"
                      onClick={onClick2}
                      href={href}
                    >
                      {buttonText2}
                    </a>
                  ) : (
                    <></>
                  )}
                  {isButton ? (
                    <Button
                      color="primary"
                      outline
                      className="btn-rounded"
                      onClick={() => navigate("apply-now")}
                    >
                      {buttonText}
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
                <img
                  src={toAbsoluteUrl("/media/illustrations/heroBgVector.svg")}
                  alt=""
                  className="floating animated-shape"
                />
              </Col>
              <Col lg={7} style={{ marginBottom: "30px" }}>
                <div className="text-end">
                  <img
                    src={toAbsoluteUrl(path)}
                    alt=""
                    className="img-fluid hero-vector"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section> */}

      <section className="hero-banner position-relative"
        style={{
          backgroundImage: `url(${toAbsoluteUrl(path)})`
        }}
      >
        <div className="hero-overlay position-absolute"></div>
        <Container className="h-100" fluid="xl">
          <div className="position-relative h-100">
            <Row className="align-items-center h-100">
              <Col xs={12} md={7} lg={6}>
                <div className="py-4">
                  <h1 className="herotitle">{heroTitle}</h1>
                  <p className="mb-30px font-20">
                    {heroDesc}
                  </p>
                  {isButton2 ? (
                    <a
                      color="primary"
                      className="btn btn-primary btn-rounded me-3 mb-3 mb-md-0"
                      onClick={onClick2}
                      href={href}
                    >
                      {buttonText2}
                    </a>
                  ) : (
                    <></>
                  )}
                  {isButton ? (
                    <Button
                      color="primary"
                      outline
                      className="btn-rounded"
                      // onClick={onClick1}
                      onClick={() => navigate("apply-now")}
                    >
                      {buttonText}
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
                <img
                  src={toAbsoluteUrl("/media/illustrations/heroBgVector.svg")}
                  alt=""
                  className="floating animated-shape"
                />
              </Col>
            </Row>
          </div>
        </Container>
        <img
          src={toAbsoluteUrl(heroImageMobile)}
          alt=""
          className="img-fluid hero-responsive d-block d-md-none"
        />
      </section>
    </>
  );
};

export default HeroBanner;
