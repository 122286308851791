import React from "react"
import { Col, Container, Row } from "reactstrap"
import { KTSVG } from "../../../helper/components/KTSVG"

type Props = {
    sectionTitle?: string,
    sectionPara?: string,
    className?: string,
    isDark?: boolean
}

const SectionHeading: React.FC<Props> = ({ sectionTitle, sectionPara, className, isDark }) => {
    return (
        <>
            <div
                className={`title text-center ${className}`}>
                <Container>
                    <Row>
                        <Col lg={8} className='mx-auto'>
                            <h2>{sectionTitle}</h2>
                            {isDark ?
                                <KTSVG
                                    path='/media/images/title_leaf.svg'
                                    className='text-white w-auto mb-10px'
                                />
                                :
                                <KTSVG
                                    path='/media/images/title_leaf.svg'
                                    className='text-success w-auto mb-10px'
                                />
                            }

                            <p className='mb-0'>{sectionPara}</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default SectionHeading
