import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import {
  FACEBOOK_SHARE,
  INSTAGRAM_SHARE,
  LINKEDIN_SHARE,
  TWITTER_SHARE,
} from "../../../_eaFruitsCms/helpers/Constants";
import { KTSVG } from "../../../helper/components/KTSVG";

const Topbar = (props: any) => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      <div className="topbar bg-primary d-flex align-items-center text-white">
        <Container xl>
          <Row className="align-items-center flex-row-reverse">
            <Col>
              <div className="d-flex align-items-center justify-content-lg-end justify-content-between">
                <ul className="text-white list-unstyled mb-0">
                  <li>
                    <a
                      href="JAVASCRIPT:void(0)"
                      className="text-white"
                      onClick={() => {
                        navigate("/contact-us");
                      }}
                    >
                      Contact us
                    </a>
                  </li>
                </ul>
                {/* <div className='d-flex lang justify-content-lg-end align-items-center'>
                                    <button type='button' className='btn btn-link active'>English</button>
                                    <span className='text-white h-18px w-1px bg-white'></span>
                                    <button type='button' className='btn btn-link '>Swahili</button>
                                </div> */}
              </div>
            </Col>
            <Col lg="auto">
              <ul className="list-unstyled mb-0 d-lg-flex top-contact-info">
                <li>
                  <a
                    href="mailto:hello@eafoods.com"
                    className="me-3 pe-1 d-flex align-items-center"
                  >
                    <KTSVG
                      path="/media/icons/ic_mail.svg"
                      className="ic me-2"
                    />
                    hello@eafoods.com
                  </a>
                </li>
                <li>
                  <a
                    href="callto:+255 672 22 77 77"
                    className="d-flex align-items-center"
                  >
                    <KTSVG
                      path="/media/icons/ic_call.svg"
                      className="ic me-2"
                    />
                    +255 672 22 77 77
                  </a>
                </li>
              </ul>
            </Col>
            <Col>
              <div className="social">
                <ul className="text-white list-unstyled mb-0 d-flex">
                  <li>
                    <a href={FACEBOOK_SHARE} target={"_blank"}>
                      <KTSVG path="/media/icons/ic_fb.svg" className="ic" />
                    </a>
                  </li>
                  <li>
                    <a href={INSTAGRAM_SHARE} target={"_blank"}>
                      <KTSVG path="/media/icons/ic_insta.svg" className="ic" />
                    </a>
                  </li>
                  <li>
                    <a href={TWITTER_SHARE} target={"_blank"}>
                      <KTSVG path="/media/icons/ic_twit.svg" className="ic" />
                    </a>
                  </li>
                  <li>
                    <a href={LINKEDIN_SHARE} target={"_blank"}>
                      <KTSVG
                        path="/media/icons/ic_linkedin.svg"
                        className="ic"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Topbar;
